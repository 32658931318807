.expenses-component {
  @include generate-columns(2);
}

.expenses-history__receipt {
  display: block;
  margin: auto;
  width: 15px;
  height: 39px;
  background: url($icon_doc) center no-repeat;
  background-size: contain;
}

.expense-form {
  display: grid;
  gap: 16px;

  @each $column in board-member-label board-member name first-divider ss-number address zipcode town second-divider bank clearing account-number third-divider description-label description amount file submit-button {
    &__#{$column} {
      grid-area: #{$column};
    }
  }

  @media (min-width: $desktop-breakpoint-smaller) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'board-member-label'
      'board-member'
      'name'
      'ss-number'
      'address'
      'zipcode'
      'town'
      'bank'
      'clearing'
      'account-number'
      'description-label'
      'description'
      'amount'
      'file'
      'submit-button';
  }

  @media (min-width: $desktop-breakpoint) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'board-member-label . .'
      'board-member name .'
      'first-divider first-divider first-divider'
      'ss-number address zipcode'
      'town . .'
      'second-divider second-divider second-divider'
      'bank clearing account-number'
      'third-divider third-divider third-divider'
      'description-label . .'
      'description description .'
      'amount . .'
      'file file file'
      'submit-button . .';
  }

  .expense-form__text-field {
    width: 100%;
    border: 1px solid $gray;
    border-radius: 7px;
    line-height: 48px;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    text-indent: 16px;
  }

  .expense-form__select {
    width: 100%;
    height: 52px;
    border: 1px solid $gray;
    border-radius: 7px;
    background: none;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    text-indent: 16px;
  }

  .expense-form__text-area {
    padding: 10px;
    width: 100%;
    height: 200px;
    border: 1px solid $gray;
    border-radius: 7px;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    resize: none;
  }

  hr {
    border: 1px solid $grayLighter;
  }

  .expense-form__board-member-label,
  .expense-form__name-label,
  .expense-form__description-label {
    font-family: $lato_font;
    font-weight: bold;
  }

  .expense-form__file {
    box-sizing: border-box;
    background: #F4F8F8;
    border: 1px dashed #C6D2D8;
    border-radius: 6px;

    &:hover {
      background: #EEF3F2;
      border: 1px dashed #5E8185;
    }

    label {
      font-family: $lato_font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
  }
}
