#contract-form {
  .form-group.selects {
    width: 100%;

    @media screen and (min-width: $lg) {
      width: 47%;
    }

    &.--first {
      @media screen and (min-width: $lg) {
        margin-right: 40px;
        width: 47%;
      }
    }

    select {
      color: $gray !important;
    }
  }

  .form-group.selects::before {
    right: 2%;
  }

  .form-group.file {
    border: 1px dashed $grayLight;
    background-color: $grayLightest;
    color: $gray;
  }

  .input-wrapper {
    float: left;
    margin: 10px 5px 20px 0;
    width: 100%;
    height: 48px;
    border: 2px solid $grayLighter;
    border-radius: 2px;
    background: white;

    input {
      margin: 0;
      padding: 15px 0 5px 10px;
      border-bottom: none;

      ~ label {
        top: 10px;
      }

      &:focus ~ label,
      &.has_value ~ label {
        top: -30px;
      }
    }

    label {
      margin-left: 5px;
      color: $gray;
      color: $gray;
      font-size: 16px;
      line-height: 26px;
    }

    @media screen and (min-width: $md) {
      margin: 10px 2px 30px 0;
      width: 33%;
    }

    span {
      position: absolute;
      top: 14px;
      right: 10px;
      color: $gray;
    }
  }
}

/* Dropdowns */
.dropdown--contracts {
  margin: 0 auto;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s ease-in-out;

  div {
    padding: 16px 16px 16px 28px;
    border: 1px solid $grayLighter;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 100%;
    background: $yellow;
  }

  .toggler--contracts {
    position: absolute;
    top: 6px;
    right: 0;
    width: 48px;
    height: 48px;
    background: transparent;
    border: 0;

    &:hover {
      cursor: pointer;
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 26px;
      cursor: pointer;
      pointer-events: none;
      border-top: 4px solid $gray;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      content: "";
      transform: translateY(-50%);
      display: block;
      -webkit-transform-origin: center;
      -moz-transform-origin: center;
      -o-transform-origin: center;
      transform-origin: center;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    font-size: 14px;
    color: $grayDarkest;
  }

  p {
    line-height: 1.25;
  }

  a {
    float: right;
    font-size: 14px;
    text-decoration: underline;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 0;
    transition: max-height 0.2s ease-in;
    overflow: hidden;
  }

  li {
    margin-top: 4px;
    padding: 12px;
    background: lighten($yellow, 80%);

    &:first-child {
      margin-top: 12px;
    }
  }

  &.__active {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

    ul {
      max-height: 999px;
    }

    .toggler--contracts {
      top: 4px;

      &:after {
        margin-top: -1px;
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }

  @media screen and (max-width: $lg) {
    p {
      max-width: 85%;
    }

    span {
      margin-bottom: 8px;
    }

    a {
      font-size: 14px;
      float: none;
    }
  }
}
