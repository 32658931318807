.content {
  &__brfanalysis {
    &-header {
      display: block;
      min-height: 130px;
      width: 100%;

      .column-header {
        margin-bottom: 20px;
        max-width: 380px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.1;
      }

      .rating {
        margin-left: 30px;
        width: initial;
        height: auto;

        .rating--a,
        .rating--aa,
        .rating--aaa,
        .rating--b,
        .rating--c {
          width: 50px;
          height: 50px;

          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: 400;
            font-size: 25px;
            transform: translate(-50%, -50%);
          }
        }

        .rating__year--a,
        .rating__year--aa,
        .rating__year--aaa,
        .rating__year--b,
        .rating__year--c {
          font-size: 18px;
        }
      }

      .mapbox {
        margin-left: 20px;
        width: 150px;
        height: 90px;
      }

      &-inner-left,
      &-inner-right {
        ul {
          display: inline-block;
          font-size: 14px;

          &:first-of-type {
            margin-right: 5px;
          }

          li {
            min-height: 16px;
          }
        }
      }
    }

    &-body {
      width: 100%;

      h3 {
        margin: 30px 0 60px 0;
        font-size: 40px;
        @media (max-width: $desktop-breakpoint) {
          font-size: 30px;
        }
      }

      .brfanalysis_form {
        width: 100%;

        .form-group {
          margin: 0;
          min-height: 160px;
          width: calc(unquote("50% - 10px"));
          height: auto;

          &:first-of-type {
            padding-right: 10px;

            &::after {
              position: absolute;
              top: 50%;
              right: -11.5px;
              width: 1px;
              height: 60%;
              background: $grayLight;
              content: "";
              transform: translateY(-50%);
            }
          }

          &__single {
            margin: 30px 0;
            min-height: auto;
            width: 100%;

            &::after {
              display: none;
            }
          }

          .check {
            position: relative;
            margin-bottom: 10px;
            background: white;
            transform: translateX(-50%);

            &.active {
              background: $purple;
            }
          }

          p.top-text {
            margin-bottom: 8px;
            text-align: center;

            span {
              display: inline;
              color: $gray;
            }
          }

          span {
            display: block;
            text-align: center;
            font-size: 15px;
            line-height: 1.2;

            strong {
              font-weight: 400;
            }
          }
        }
      }

      .advantages {
        margin-top: 0;
        text-align: center;

        ul {
          position: relative;
          display: table;
          margin: auto;
          list-style-image: url($icon_checkmark);
          text-align: left;

          li {
            padding-left: 0;
            color: $grayDarker;
            vertical-align: middle;
            font-size: 15px;
            line-height: 23px;

            span strong.text-danger {
              color: darken(red, 20%);
            }
          }
        }
      }

      .brf-analysis-example {
        display: block;
        margin: 25px 0;
      }

      .happy-customers {
        @media (max-width: $desktop-breakpoint) {
          right: 10px;
        }
      }
    }
  }
}

.page_brfanalysis .logo,
.container--valuation .logo {
  width: 145px;
  height: 40px;

  svg {
    width: 145px;
    height: 40px;

    path {
      fill: $purple;
    }
  }
}

.page_brfanalysis {
  margin: auto;
  padding: 20px 0;
  min-width: 675px;
  width: 57.5555%;
  color: $grayDarker;
  @media (max-width: $desktop-breakpoint) {
    min-width: initial;
    width: 95%;
  }

  .header {
    &__brfanalysis {
      position: relative;
      overflow: visible;
      margin-bottom: 30px;
      width: 100%;
      height: 60px;

      > div {
        padding: 0;
      }

      .sponsor {
        &.hemnet {
          margin-top: -2px;
        }
      }
    }
  }

  &.brfanalysis--download {
    h3 {
      margin: 30px 0;
    }

    p {
      margin: 20px 0 10px 0;
      line-height: 22px;
    }

    .content__brfanalysis-header-left-side {
      float: none;
      margin: auto;
      width: 380px;
    }

    .new-button {
      margin-top: 20px;
    }
  }

  &.brfanalysis--purchase,
  &.valuation--purchase {
    .inline-analysis-modal {
      &::before {
        position: absolute;
        top: -17.5px;
        left: 50%;
        padding: 10px 30px;
        border-radius: 4px;
        background: $gray;
        color: white;
        content: "Erbjudande";
        font-size: 14px;
        transform: translateX(-50%);
      }
    }
  }
}

.valuation--purchase {
  .advantages {
    margin: 20px 40px !important;
  }
}

.brfanalys_modal {
  .popup-module-content {
    > div[data-target="brfanalys.stageDownload"] {
      i {
        color: $green;
      }

      > p {
        margin: 20px 0 10px 0;
      }

      > a {
        display: block;
        clear: both;
        margin: 20px 0 auto 0;
      }
    }
  }
}

.inline-analysis-modal {
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
}

#klarna-checkout-container {
  overflow: hidden;
}

@media (max-width: $md) {
  .content {
    &__brfanalysis {
      &-header {
        display: grid;

        &-left-side.float-left,
        &-right-side.float-right {
          float: none;
          margin: auto;
          margin-top: 0;
          width: 75%;

          grid-row: 1;
        }

        &-left-side.float-left {
          position: relative;
          margin-bottom: 10px;

          grid-column: 1;

          .rating {
            margin-top: 0;
            margin-left: 0;
            width: 100%;
            transform: scale(0.7);
          }
        }

        &-right-side.float-right {
          grid-column: 2;
        }

        .mapbox,
        .rating {
          display: none;
        }

        &-inner-left.float-left,
        &-inner-right.float-right {
          display: block;
          float: none;
        }

        &-inner-left.float-left {
          ul:last-of-type {
            float: right;
          }
        }
      }
    }
  }
}

@media (max-width: $desktop-breakpoint-smaller) {
  .mobile-heading {
    margin-bottom: 20px;
    font-size: 25px;
  }
  .header__brfanalysis {
    .logo {
      width: 122px;
      height: 35px;
      @media (max-width: 375px) {
        width: 101px;
        height: 29px;
      }
    }

    .sponsor {
      height: 25px;
      @media (max-width: 375px) {
        height: 29px;
      }
    }
  }
  .content__brfanalysis {
    &-header {
      margin-bottom: 50px;

      &-left-side.float-left {
        margin-bottom: 15px;

        grid-row: 1;
        grid-column: 1;
      }

      &-right-side.float-right {
        grid-row: 2;
        grid-column: 1;
      }

      .mapbox {
        display: none;
      }
    }

    &-body {
      .brfanalysis_form .form-group {
        min-height: 140px;
        width: 100%;

        &:first-of-type {
          margin-top: 20px;
          margin-bottom: 20px !important;
          padding-right: 0;

          &::after {
            top: initial;
            right: initial;
            bottom: 0;
            left: 50%;
            width: 30%;
            height: 1px;
            transform: none;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
