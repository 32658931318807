[data-controller="org-loan"] {
  .no--data {
    font-style: italic;
  }

  .grid.__gap.__xs {
    padding-bottom: 20px;
  }

  .popupMsg {
    position: absolute;
    top: 0;
    right: 0;
    display: block;

    span {
      margin: 0 12px;
      padding: 13px;
      width: 222px;
      height: 40px;
      border: 1px solid rgba(255, 214, 0, 0.5);
      border-radius: 3px;
      background: rgba(245, 209, 77, 0.05);
      color: $gray;
      font-size: 14px;
      line-height: 16px;
    }

    a.icon--print {
      margin: 0 12px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $grayLighter;
      color: black;

      &::before {
        position: absolute;
        top: 12px;
        right: 23px;
        color: $gray;
      }
    }
  }

  .broker-img {
    position: relative;
    overflow: hidden;
    align-self: flex-start;
    margin: 0 20px 0 0;
    min-width: 100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .loan--about {
    h5 {
      color: black;
    }

    div:first-of-type {
      display: flex;
      flex-direction: row;

      div:nth-child(2) p:first-of-type {
        color: $gray;
      }

      div:last-of-type {
        align-self: center;
      }
    }
  }

  .read-more {
    margin-top: 34px;
    padding: 24px;
    background-color: $grayLightest;
    color: $grayDarker;
  }

  #brf-loans-table {
    .tabulator-footer {
      display: none;
    }
  }

  @media screen and (max-width: calc(#{$lg} - 1px)) {
    .grid.__gap.__xs {
      .column {
        margin: 0;

        &.__40 {
          width: 40%;
        }

        &.__60 {
          width: 60%;
        }
      }
    }
  }

  @media screen and (max-width: calc(#{$md} - 1px)) {
    .popupMsg {
      top: -1.5%;
      right: -3%;

      span {
        width: 133px;
        height: 40px;
        font-size: 10px;
        line-height: 16px;
      }

      a.icon--print {
        display: none;
      }
    }

    .grid.__gap.__xs {
      .column {
        margin: 0;

        &.__40 {
          width: 100%;
        }

        &.__60 {
          width: 100%;
        }
      }
    }
  }

  @media print {
    html,
    body {
      height: 99%;
      page-break-before: avoid;
      page-break-after: avoid;
    }

    body header ~ main {
      margin-top: 0;
    }

    header,
    nav,
    footer,
    .breadcrumbs {
      display: none;
    }

    section {
      margin: 0;
      padding: 0;
    }

    .popupMsg {
      top: 0;
      right: 0;

      span {
        margin: 0;
      }

      a.icon--print {
        display: none;
      }
    }

    .floating--grid {
      margin-top: 20px;

      .float-left {
        display: none;
      }
    }

    .tabulator-footer,
    .read-more {
      display: none !important;
    }

    .grid.__gap.__xs {
      display: flex;
      margin-top: 20px;

      .column__50.__xs {
        max-width: 300px;
      }
    }
  }

  [data-target="org-loan.loanTimeChart"] svg > g > g:last-child,
  [data-target="org-loan.loanPerSqmChart"] svg > g > g:last-child {
    pointer-events: none;
  }
}
