.share-modal {
  &__button {
    img {
      margin-right: 4px;
    }
  }
}

.broker__link {
  color: $purple;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
}

#module-omradet {
  padding-top: 8px;

  .table__header {
    margin: 24px auto 8px;
  }
}
