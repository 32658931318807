/* Styles for the _apartment.haml partial */

.section--lagenheter {
  h5 {
    margin-bottom: 24px;
  }

  hgroup span svg {
    ellipse {
      fill: $purple;
    }

    path:nth-child(1) {
      fill: $purple;
    }

    path:nth-child(2) {
      fill: white;
    }
  }
}

/* Remove zoom, needed for section Chrome/Safari */

.section--lagenheter {
  .apartments--chart {
    position: relative;
    margin: 0 auto;
    width: 410px;
    height: 300px;

    @media (max-width: $desktop-breakpoint-smaller) {
      right: 10%;
    }

    @media (min-width: $desktop-breakpoint) {
      zoom: 1;
    }
  }
}

/* PRevious styles pre 2020 */

.apartments__chartcontainer {
  position: relative;
}

.apartments__chart-text-header {
  margin: 0 30px 0 30px;
  @media (max-width: $desktop-breakpoint) {
    zoom: 0.8;
  }

  img {
    width: 50px;
  }
}

.apartments__chart {
  position: relative;
  margin: 0 auto;
  width: 410px;
  height: 300px;
  zoom: 0.6;

  @media (max-width: $desktop-breakpoint-smaller) {
    right: 10%;
  }

  @media (min-width: $desktop-breakpoint) {
    zoom: 1;
  }
}

/* Remove zoom, needed for section Chrome/Safari */

.section--lagenheter {
  .apartments--chart {
    position: relative;
    margin: 0 auto;
    width: 410px;
    height: 300px;

    @media (max-width: $desktop-breakpoint-smaller) {
      right: 10%;
    }

    @media (min-width: $desktop-breakpoint) {
      zoom: 1;
    }
  }
}

.apartmentsgradient {
  position: relative;
  height: 550px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 150px;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
    content: '';
  }
}

.component--apartments {
  overflow: hidden;

  a {
    color: $purple;

    &:hover,
    &:visited,
    &:focus {
      color: $purple;
      text-decoration: underline;
    }
  }
}

@keyframes readmore {
  0% {
    max-height: 300px;
  }
  99% {
    max-height: 1000px;
  }
  100% {
    max-height: initial;
  }
}

.apartments--fullview {
  height: initial;
  animation: readmore 0.4s forwards;
}

.apartments__address {
  margin-bottom: 8px;
  font-weight: 400;
}

.apartments__valuation__header {
  text-align: center;

  h2 {
    margin: 30px;
    font-size: 20px;
  }
}

.apartments__chart-text {
  img {
    margin-top: 30px;
    height: 75px;
  }

  h2 {
    color: $grayDarker;
    font-size: 48px;
  }

  h3 {
    margin-top: 5px;
    font-size: 14px;
  }
}
