.folder-page {
  .folder-modal__button {
    svg {
      margin-right: 5px;
    }
  }

  .folder-table {
    border-collapse: collapse;
    min-width: 500px;
    width: 100%;
    table-layout: auto;

    &.annual-table {
      min-width: 270px;
    }

    &.other-table {
      min-width: 550px;
    }

    th,
    td {
      padding: 12px 8px;
      text-align: left;
    }

    thead {
      background: lighten($lightBlue, 40%);
      font-weight: 400;

      th {
        &:nth-last-child(1) {
          .reorder {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &.active {
              svg {
                path {
                  fill: #0000ff;
                }
              }
            }
          }
        }

        svg {
          margin-left: 5px;
        }

        &.short-label {
          text-align: right;
        }

        &.file-year {
          width: 70px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #eeeeee;
        font-weight: 400;
      }
    }

    .folder {
      display: flex;
      align-items: center;
    }

    th:first-child,
    td:first-child {
      width: 65%;

      svg {
        margin-right: 10px;
      }
    }

    th:nth-last-child(1),
    td:nth-last-child(1) {
      width: 6%;

      svg {
        display: flex;
        align-items: center;
      }
    }

    th:nth-last-child(2),
    td:nth-last-child(2) {
      width: 29%;
      text-align: left;
    }
  }

  .order-icon {
    cursor: pointer;

    &.active {
      svg {
        path {
          fill: #0000ff;
        }
      }
    }
  }

  .dots-menu {
    &:hover {
      svg {
        path {
          fill: #666666;
        }
      }
    }

    .nav {
      position: relative;

      ul {
        background: #ffffff;
        padding: 0;

        li {
          color: #666666;
          width: 100px;
          position: relative;
          height: 100%;
        }
      }

      .dropdown-trigger {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
          display: initial;
        }
      }

      .dropdown-item {
        text-decoration: none;
        color: #666666;
        padding: 15px;
        transition: 0.2s all linear;
        display: flex;
        font-size: 14px;
        white-space: nowrap;
        align-items: center;

        svg {
          margin-right: 5px;
        }

        &:hover {
          background: #e3f2fd;
          color: black;
          transition: 0.2s all linear;
        }
      }

      .dots-item:hover {
        cursor: pointer;
      }

      .dropdown {
        display: none;
        position: absolute;
        top: 25px;
        right: -25px;
      }

      &:hover {
        .dropdown {
          display: block;
          z-index: 999;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        bottom: -25px;
        width: 100%;
      }
    }
  }

  .pagintaion-container {
    position: absolute;
    visibility: hidden;
  }
}
