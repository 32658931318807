.section--tags {
  .tags {
    margin-top: 30px;

    li {
      border: 2px solid $purple;
      background: rgba(89, 39, 126, 0.75);
      margin: 10px 0 10px;
      width: fit-content;
      color: $white;
      padding: 10px 20px 10px 36px;
      font-size: 16px;
      border-radius: 99px;
      height: 52px;
      line-height: 28px;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      &:hover > a.delete {
        visibility: visible;
      }

      a.delete {
        display: inline-block;
        visibility: hidden;
        width: 14px;
        height: 14px;
        padding-left: 6px;

        i {
          color: $white;
        }
      }
    }
  }
}
