/* Style section summary */
.section--summering {
  #ph-slider {
    a {
      z-index: 5;
    }
  }

  .cover {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 360px;
    background-position: center center;
    background-size: none;
    background-repeat: no-repeat;

    &.--cover {
      background-size: cover;
    }
  }

  /* Used to remove the visual difference in padding between box w. svg vs w/o */
  .board-message {
    .header {
      padding-bottom: 4px !important;
    }
  }

  .title {
    padding-top: 36px;
    color: white;
    text-align: center;
    font-weight: 400;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
                      to top,
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0.5)
      );
      content: "";
    }
  }

  .slider-badge {
    position: absolute;
    left: 16px;
    width: 96px;
    height: 96px;
    z-index: 3;
    color: white;
    bottom: 16px;

    div {
      text-align: center;
    }

    .slider-rating {
      background: linear-gradient(45deg, #50ca75 0 50%, $green 50% 100%);
      height: 72px;
      line-height: 72px;
      font-size: 64px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      span {
        font-size: 36px;
        margin-left: -2px;
        vertical-align: top;
      }
    }

    .label {
      position: relative;
      height: 24px;
      font-weight: 700;
      background: $grayDarker;
      text-transform: uppercase;
      text-align: left;
      padding-left: 18px;
      letter-spacing: 1px;
      font-size: 10px;
      line-height: 23px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      &::after {
        position: absolute;
        right: 40px;
        top: 16px;
        content: url($icon_allabrf_plus);
        zoom: 35%;
      }
    }
  }

  .tags {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 2;
    width: 100%;
    text-align: center;

    li {
      border: 2px solid $purple;
      background: rgba(89, 39, 126, 0.75);
      margin: 8px 8px 0 8px;
      max-width: 240px;
      display: inline-block;
      color: white;
      padding: 0 6px;
      font-size: 14px;
      font-weight: 700;
      border-radius: 99px;
      height: 32px;
      line-height: 28px;
    }
  }

  .rating h3,
  .board-message h3 {
    font-size: 18px;
  }

  .description {
    margin: auto;
    max-width: 670px;
    text-align: center;
  }

  .grade--wrapper {
    text-align: center;

    .grade {
      display: inline-block;
      margin-bottom: 16px;
    }
  }

  .board-message {
    height: 100%;

    h5 {
      display: inline-block;
    }
  }

  .partner {
    margin-bottom: 16px;
    text-align: center;

    &.partner_0 {
      svg {
        filter: invert(80%) sepia(96%) saturate(1360%) hue-rotate(255deg) brightness(87%) contrast(96%);
      }
    }

    &.partner_2 {
      svg {
        path:first-of-type {
          display: none;
        }
      }
    }

    svg {
      display: block;
      margin: 0 auto 8px;
      width: 100%;
      height: 24px;
    }

    a {
      display: block;
      color: black;
    }
  }

  .map {
    min-height: 260px;
    width: 100%;
    border-radius: 2px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.follow-modal__button {
  &.__disabled span {
    -webkit-mask: url($icon_star_filled) no-repeat 100% 100%;
    mask: url($icon_star_filled) no-repeat 100% 100%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: $purple;
  }
}

@media screen and (max-width: $md) {
  .section--summering {
    #ph-slider {
      .slider-badge {
        bottom: 78px;
      }

      .tags {
        max-width: 220px;
        text-align: right;
        bottom: 78px;

        li {
          margin: 4px;
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .section--summering {
    .grade--wrapper {
      display: flex;
      align-items: center;
      margin: 0 auto;
      max-width: 320px;
      text-align: left;

      .grade {
        margin: 0 24px 0 0;
      }
    }

    .slider-badge {
      top: 24px;
      left: 16px;
    }

    #ph-slider {
      .tags {
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        max-width: none;

        li {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .title {
        font-size: 54px;
      }

      .badge {
        top: 24px;
        left: 16px;
      }
    }

    .options {
      margin-top: -76px;
    }
  }
}

@media screen and (min-width: $lg) {
  .section--summering .partner {
    margin: 0;
  }
}

@media screen and (min-width: $md) and (max-width: $lg) {
  .section--summering {
    .grid.__gap {
      justify-content: space-between;
    }

    .column.__33.partner {
      padding-left: 10px;
      width: 33%;

      &:first-of-type {
        padding-left: 0;
      }
    }

    .grid.__gap {
      .column.__50 {
        width: 50%;

        &:first-of-type {
          padding-right: 10px !important;
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .section--summering #ph-slider .tags li {
    margin-right: 12px;
    font-size: 16px;
    height: 44px;
    padding: 0 12px;
    line-height: 40px;
    font-weight: 400;
  }
}
