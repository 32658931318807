.container {
  &--valuation {
    padding-top: 40px;

    .logo {
      margin-left: 40px;
    }

    .partner-logo {
      margin-right: 40px;

      img {
        width: 144px;
      }
    }

    > h3 {
      margin: 40px auto 20px;
      font-weight: 400;
      font-size: 30px;
      line-height: 35px;
    }

    .landing-block__title,
    .advantages {
      margin: 20px 0 !important;
    }

    .select__wrapper--half,
    .input-wrapper {
      input,
      select {
        border-bottom: 1px solid $gray;
        background: transparent;
      }
    }

    .happy-customers {
      width: 80px;
      height: 80px;

      span {
        font-size: 12px;
      }
    }

    .advantages {
      margin: 20px 40px !important;
    }

    #apartment-form {
      overflow: hidden;
    }
  }
}

/* Moved from economy.scss as do not seem to belong there */

.select__wrapper--half {
  position: relative;
  display: inline-block;
  width: 50%;

  select {
    display: inline-block;
    margin-bottom: 30px;
    padding-left: 8px;
    width: 100%;
    height: 34px;
    border: none;
    border-bottom: 1px solid $grayLighter;
    border-radius: 0;
    background-color: white;
    color: $gray;
    font-weight: 400;
    font-size: 18px;

    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &::before {
    position: absolute;
    top: 13px;
    right: 10px;
    display: block;
    width: 0;
    height: 0;
    border-top: 4px solid $gray;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: '';
    transform: translateY(-50%);
    pointer-events: none;
  }

  @media(max-width: $desktop-breakpoint-smaller) {
    width: 100%;
  }
}
