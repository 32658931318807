.component--current-insurance {
  font-size: 13px;

  .grid {
    margin-top: 16px;
    height: 100%;
  }

  li span:first-child {
    color: $gray;
  }

  .list--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    line-height: 22px;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 96px;
    height: auto;
  }

  @media (max-width: calc(#{$md} - 1px)) {
    .column {
      .grid {
        flex-direction: column;
      }
    }
    .__brand {
      display: none;
    }
  }

  @media (min-width: $md) and (max-width: calc(#{$lg} - 1px)) {
    .__divider::after {
      position: absolute;
      top: 0;
      left: calc(100% - 9px);
      display: block;
      width: 2px;
      height: 100%;
      background: #eeeeee;
      content: "";
    }

    .grid .column.__50.__xs.__gridAligned {
      padding-left: 10px !important;
    }

    .grid .column.__50.__xs.__columnAligned {
      padding-left: 5px !important;
    }
  }

  @media (min-width: $lg) {
    .__divider::after {
      position: absolute;
      top: 0;
      left: calc(100% + 0px);
      display: block;
      width: 2px;
      height: 100%;
      background: #eeeeee;
      content: "";
    }

    .grid .column.__50.__xs.__columnAligned {
      padding-left: 16px !important;
    }
  }

  [data-target="org-insurance.premiumsChart"] svg > g > g:last-child {
    pointer-events: none;
  }
}
