.parking-main-container {
  clear: both;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .parking-item-container {
    height: 279px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 6px;
    padding: 5px;
    width: 32%;
    margin-right: 15px;
    position: relative;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .footer.align-right {
      display: flex;
      justify-content: flex-end;
    }

    .link-info {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      text-align: center;
      text-decoration-line: underline;
      color: #59277e;
    }

    .inner-button-container {
      margin: auto;
      position: absolute;
      bottom: 3%;
      font-size: 15px;
      font-style: normal;
      text-align: center;

      .button--tertiary.add-place {
        background: $green;

        &:hover {
          background: $lightBlue;
        }
      }

      .button--tertiary {
        color: #111111;
        text-transform: none;
        font-weight: normal;
        width: 266px;
        background: $lightBlue;

        &:hover {
          background: $grayBlue;
        }
      }

      .button--tertiary.leave-queue {
        background: $grayLightest;

        &:hover {
          background: $lightBlue;
        }
      }

      .button--tertiary.leave-place {
        background: #ff7675;

        &:hover {
          background: #942c0c;
        }
      }
    }

    .title {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #111111;
      margin-bottom: 15px;
    }

    .key,
    .value {
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;
      color: #707070;
      margin-bottom: 7px;
      text-align: center;
    }

    .value {
      color: #111111;
      font-size: 13px;
    }

    .ingen {
      color: #111111;
    }
  }

  .icon-garage {
    margin-top: 10px;
    width: 64px;
    height: 63.01px;
    background: url($icon_garage) center center no-repeat;
  }

  .icon-parking {
    margin-top: 10px;
    width: 64px;
    height: 65px;
    background: url($icon_parkering) center center no-repeat;
  }

  .icon-carport {
    margin-top: 10px;
    width: 64px;
    height: 69px;
    background: url($icon_carport) center center no-repeat;
  }
}

.dialog-container {
  .body {
    text-align: center;
    align-items: center;
  }

  .footer {
    border-top: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer.align-right {
    display: flex;
    justify-content: flex-end;
  }
}

.sign-parking-header {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
}

.sign_document .icon-bankid {
  display: block;
  margin: auto;
  padding-top: 20px;
  width: 105.38px;
  height: 100px;
  background: url($icon_bank_id) center center no-repeat;
}

#contact-body {
  .link-info {
    margin-top: 30px;
  }
}

.parkig-info {
  text-align: center;

  .icon-url {
    height: 14px;
    width: 14px;
    vertical-align: middle;
  }
}
