.tabulator {
  border: 0;
  border-radius: 4px;
  background: white;

  .tabulator-tableholder {
    border-top: 0;
    border-right: 1px solid $grayLighter;
    border-bottom: 1px solid $grayLighter;
    border-left: 1px solid $grayLighter;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .tabulator-table {
    overflow: hidden;
    border: 0;
    color: $grayDarkest;
  }

  .tabulator-headers .tabulator-col {
    padding: 0 16px;
    height: 50px;
    background: #6a3d8b;
    color: white;
    line-height: 50px;

    &:has(.tabulator-header-filter) {
      height: 110px;
    }

    .tabulator-col-content {
      padding: 0;

      .tabulator-header-filter {
        border-top: 1px solid desaturate(lighten($purple, 20%), 10%);
      }
    }
  }

  .tabulator-arrow {
    top: 20px !important;
    right: 0;
    border: 0 !important;

    &:after {
      display: block;
      width: 8px;
      height: 12px;
      background: url(~icons/icon_sort.svg) 50% 50% no-repeat;
      background-size: 100%;
      content: '';
    }
  }

  .tabulator-header {
    border-bottom: 0;
    background: desaturate(lighten($purple, 10%), 10%);

    .tabulator-frozen.tabulator-frozen-left {
      border-right: 1px solid transparent;
    }

    .tabulator-frozen-left {
      background: $purple !important;
    }

    .tabulator-col {
      border-color: desaturate(lighten($purple, 20%), 10%);

      .tabulator-col-content {
        .tabulator-col-sorter {
          top: -12px !important;
        }
      }

      .tabulator-col-content .tabulator-arrow {
        opacity: 0.5;
      }

      &.tabulator-sortable {
        &[aria-sort='none'] .tabulator-col-content .tabulator-arrow {
          opacity: 0.5;
        }

        &[aria-sort='asc'] .tabulator-col-content .tabulator-arrow {
          opacity: 1;
        }

        &:hover {
          background: desaturate(lighten($purple, 10%), 10%);

          .tabulator-header-filter {
            border-top: 1px solid desaturate(lighten($purple, 30%), 10%);
          }
        }
      }
    }
  }
}

.tabulator-menu-item {
  margin-left: 15px;

  .unchosen-dot {
    position: absolute;
    top: 50%;
    left: -10px;
    margin-right: 50px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $grayLighter;
    transform: translateY(-50%);
  }

  .chosen-dot {
    position: absolute;
    top: 50%;
    left: -10px;
    margin-right: 50px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $purple;
    transform: translateY(-50%);
  }
}

.tabulator-row {
  height: 40px;
  line-height: 40px;

  .tabulator-frozen.tabulator-frozen-left {
    position: relative;
    overflow: hidden;
    border-right: 1px solid transparent;
    box-shadow: $grayLighter 8px 0 9px -1px;

    &:after {
      position: absolute;
      top: 0;
      right: -16px;
      z-index: 99;
      display: block;
      width: 16px;
      height: 40px;
      background-image: linear-gradient(
                      to left,
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0.05)
      );
      content: '';
    }
  }

  .tabulator-cell {
    padding: 0 16px;
  }

  &.tabulator-row-even {
    background: $grayLightest;
  }

  &.tabulator-selectable:hover {
    background: $lightBlue;
  }

  .tabulator-cell {
    border-right: 1px solid $grayLighter;
  }
}

.tabulator .tabulator-footer {
  margin-top: 12px;
  padding: 0;
  border: 0;
  background-color: white;

  .tabulator-page {
    padding: 0 12px;
    height: 35px;
    border: 1px solid $gray;
    background: white;
    color: $grayDarker;
    font-size: 13px;
    line-height: 35px;

    &:not(.disabled):hover {
      background: white;
      color: $grayDarker;
    }

    &:not([disabled]):hover {
      border-color: $green;
      background: white;
      color: $grayDarkest;
    }

    &.active {
      border-color: $gray;
      background: $gray;
      color: white;

      &:hover {
        border-color: $gray;
        background: $gray;
        color: white;
      }
    }
  }
}

.tabulator-page-size {
  padding: 0 12px;
  height: 35px;
  border: 1px solid $gray;
  background: white;
  color: $grayDarker;
  font-size: 13px;
  line-height: 35px;
}

.tabulator-header .centerAlignedContent {
  text-align: center !important;
}

.tabulator-tableHolder,
.tabulator-tableholder {
  .centerAlignedContent {
    text-align: center !important;
  }
}

.tabulator-header .rightAlignedHeader {
  text-align: right !important;
}

.budget-table {
  .tabulator-tableholder {
    overflow-x: auto;
    overflow-y: inherit;
    border-right: 0;
    border-left: 0;
  }

  .tabulator-row {
    border-top: 0;
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;

    &.group,
    &.result {
      font-weight: bold;

      &:not(:first-child) {
        border-top: 1px solid #eeeeee;
      }
    }

    &.visible {
      display: block !important;
    }
  }

  .tabulator-cell:not(:first-child) {
    text-align: right;
  }

  .tabulator-editing {
    height: 39px !important;
  }
}

#budget-year-table,
#budget-multiple-year-table {
  .tabulator-tree-level-1 {
    .tabulator-cell {
      &:first-child {
        padding-left: 42px;
      }
    }
  }

  .tabulator-cell,
  .tabulator-col {
    &:first-child {
      width: 300px;
    }
  }
}

#budget-cashflow-table {
  margin-top: 30px;

  .group:not(:last-child) {
    font-weight: inherit;
  }

  .tabulator-row:first-child,
  .tabulator-row:nth-last-child(2) {
    font-weight: bold;
  }
}

#budget-fee-analysis-table {
  .tabulator-row:nth-last-child(3) {
    border-top: solid 3px;
    border-bottom: solid 3px;
  }

  .tabulator-row:nth-last-child(1) {
    .tabulator-cell:not(:first-child) {
      &::after {
        content: '%';
      }
    }
  }
}

#budget-fee-analysis-table,
#budget-cashflow-table {
  .tabulator-cell,
  .tabulator-col {
    &:first-child {
      width: 255px;
    }
  }
}

#finance-results .tabulator-tree-level-0 {
  font-weight: bold;
}
