input.parsley-error,
select.parsley-error,
textarea.parsley-error,
label.parsley-error {
  border-bottom: 1px solid darken(red, 20%) !important;
  color: $gray;
}

.parsley-errors-list {
  position: relative;
  top: -24px;
  padding-left: 8px;
  border-radius: 5px;
  color: darken(red, 20%);
  list-style-type: none;
  text-align: left;
  font-size: 12px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}
