//no organizations
.profile-organization-empty {
  font-size: 1.2rem;
  line-height: 1.5;
}

.box-shortcuts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  font-size: 0;

  li {
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: 130px;
    width: calc(1 / 5 * 100% - (1 - 1 / 5) * 10px);
    text-align: center;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    display: inline-block;
    width: 100%;
    height: 132px;
    border: 1px solid $grayLightest;
    border-radius: 4px;
    background: white;
    box-shadow: 0 5px 20px $grayLightest;
    color: $purple;
    text-decoration: none;
    font-size: 14px;
    transition: box-shadow 0.15s, border-color 0.15s ease-in;

    &:hover {
      border: 1px solid $purple;
      box-shadow: 0 10px 40px $grayLighter;
    }
  }

  a.disabled:hover {
    border: 1px solid $grayLightest !important;
    box-shadow: 0 5px 20px $grayLightest !important;
  }

  a > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    /* Needed or txt content is too tight */
    padding: 5px;
    width: 100%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &--note {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: $lightBlue;
    color: rgba(17, 17, 17, 0.75);
    font-size: 14px;
    line-height: 23px;
  }

  &--text {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
  }

  svg {
    display: block;
    margin: 0 auto 12px;
    width: 36px;
    height: 36px;

    path {
      fill: $purple;
    }
  }

  .disabled {
    svg path {
      fill: $grayLight;
    }

    span {
      color: $grayLight;
    }

    .profile-tool-tip {
      width: 130px;
      color: white;
    }

    &:hover {
      border: 1px solid transparent;

      .profile-tool-tip {
        display: block;
      }
    }
  }
}

//profile content
.profile {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 16px 0;
  max-width: 1244px;

  section {
    margin-left: 24px;
    padding: 40px;
    max-width: 1050px;
    width: 100%;
    border-radius: 4px;
    background: white;
  }

  .profile-block {
    position: relative;

    .form-group {
      &.text,
      &.number {
        margin: 40px 0;
      }
    }

    .profile-page-header {
      .component--profile-header {
        margin-bottom: 24px;

        svg {
          margin-right: 8px;
        }

        h2 {
          line-height: 36px;
        }

        .breadcrumbs {
          margin: 12px 0;
          line-height: 20px;

          li {
            &:last-child {
              color: $grayDarkest;

              &:after {
                content: none;
              }
            }
          }

          @media (min-width: 1024px) {
            line-height: 13px;
          }
        }
      }

      p {
        &:first-of-type {
          margin-top: 24px;
        }
      }
    }

    &-header {
      a:hover > div {
        border: 1px solid $gray;

        div {
          color: $gray;
        }

        svg path {
          fill: $gray;
        }
      }

      &--image {
        position: relative;
        max-width: 872px;
        height: 200px;
        border: 1px solid $grayLightest;
        border-radius: 3px;
        background-color: $grayLightest;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        div {
          position: absolute;
          right: 24px;
          bottom: 24px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: $grayLight;
          font-weight: 300;
          line-height: 30px;

          svg path {
            fill: $grayLight;
          }
        }
      }

      span {
        color: black;
      }

      .float-left span {
        display: block;

        &:first-of-type {
          font-weight: 700;
          font-size: 22px;
        }

        &:last-of-type {
          font-weight: 300;
          font-size: 20px;
        }
      }

      .float-right {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 19px;

        svg {
          margin-right: 8px;
        }
      }
    }

    &--wall {
      display: flex;
      align-items: center;
      min-height: 52px;
      height: auto;

      > div:last-of-type {
        flex-grow: 2;
        max-width: 620px;
      }

      &--poster--img {
        margin-right: 8px;
        width: 64px;
        height: 64px;
        border-radius: 64px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &--poster {
        overflow: hidden;
        margin-right: 32px;
        min-width: 150px;
        max-width: 300px;
        line-height: 64px;
      }

      &--date {
        color: $grayLight;
        font-size: 14px;
      }

      &--post {
        font-size: 16px;
      }

      &--comments {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    .search-container {
      position: relative;
      top: inherit;
      left: inherit;
      padding: 10px;
      background-color: inherit;
      box-shadow: none;
      transform: none;


      &.budget-table {
        margin-top: -12px;
        padding: 0 0 12px;
        max-width: 870px;
        width: 100%;

        .search-box {
          input {
            padding: 12px;
            height: 40px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: calc(#{$lg} - 1px)) {
  .profile {
    section {
      margin-left: 0;
      padding: 48px 16px;
      width: 100% !important;

      .profile-block--wall {
        display: block;
        height: auto;
      }

      .profile-block {
        .profile-page-header {
          h2 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

.padding-r-32-n-l {
  padding-right: 32px !important;
  padding-left: 0 !important;
}

.board-rooms--load-error {
  display: block;
  padding: 10px;
  border: 1px solid $yellow;
  background: lighten($yellow, 80%);
}

// Specific styling for _subheader partial

.heading {
  margin-bottom: 36px;
}

@media screen and (min-width: $md) {
  .heading {
    display: flex;
    margin-left: -16px;

    .image,
    .details {
      padding-left: 16px;
      width: 50%;
    }
  }
}

@media screen and (min-width: $lg) {
  .heading {
    margin-left: -32px;

    .image,
    .details {
      padding-left: 32px;
    }
  }
}

.heading {
  .image {
    height: 128px;
    border-radius: 2px;
    background-position: 50%;
    background-clip: content-box;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (min-width: $md) {
  .heading .image {
    height: auto;
  }
}

.heading {
  .details {
    div {
      display: flex;

      ul {
        margin-top: 10px;
        width: 50%;
        color: $gray;
        font-size: 13px;
      }

      li {
        line-height: 18px;
      }

      span {
        color: $grayDarkest;
      }
    }
  }
}

@media screen and (max-width: calc(#{$md} - 1px)) {
  .heading {
    .details {
      margin-top: 24px;
    }
  }
}

//footer partial of components of board room

.component--footer {
  .manager--about {
    h5 {
      color: black;
    }

    div:first-of-type {
      display: flex;
      flex-direction: row;

      div:nth-child(2) p:first-of-type {
        color: $gray;
      }

      div:last-of-type {
        align-self: center;
      }
    }
  }

  .broker-img {
    position: relative;
    overflow: hidden;
    align-self: flex-start;
    margin: 0 20px 0 0;
    min-width: 100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .left-box {
    display: flex;
    flex-direction: row;
    padding-right: 25px;
  }

  .right-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $grayLighter;

    a.button--primary {
      margin-left: 30px;
    }
  }

  @media screen and (max-width: calc(#{$lg} - 1px)) {
    .right-box {
      padding-left: 25px;
    }

    .left-box {
      width: 60%;
    }
  }

  @media screen and (max-width: calc(#{$md} - 1px)) {
    .manager--about {
      div:first-of-type {
        display: flex;
        flex-direction: row;

        div:last-of-type {
          align-self: center;
        }
      }

      div:last-of-type {
        margin-left: 0;
      }
    }

    .broker-img {
      min-width: 50px;
      width: 50px;
      height: 50px;
    }

    .right-box {
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: 0;
      border-left: none;

      div {
        align-self: flex-start;
      }

      div:first-of-type {
        margin-top: 18px;
        margin-left: 70px;
      }

      a.button--primary {
        align-self: center;
        margin-top: 22px;
        margin-left: 0;
      }
    }
  }

  @media print {
    .right-box {
      justify-content: flex-start;

      a.button--primary {
        display: none;
      }
    }

    .broker-img {
      display: none !important;
    }

    .manager--about div:last-of-type {
      padding-top: 0 !important;

      p {
        display: none;
      }
    }
  }
}
