.sign_document {
  margin: auto;
  max-width: 560px;
  font-size: 14px;
  line-height: 24px;

  @media screen and (max-width: $mobile-width) {
    margin: 0 auto 20px auto;
  }

  #sign__form {
    margin-bottom: 54px;
  }

  &--example {
    position: relative;
    margin: 0 auto;
    max-width: 480px;
    font-weight: bold;
    font-size: 14px;
    line-height: 66px;

    svg {
      position: absolute;
      top: 19px;
      margin-left: -25px;
    }
  }

  h3 {
    font-size: 18px;
  }

  &--description {
    margin-bottom: 20px;
  }

  ul {
    margin-left: 40px;

    li {
      margin: 20px 0;

      svg,
      span {
        display: inline-block;
        vertical-align: middle;
      }

      svg {
        margin-right: 15px;
      }

      span {
        width: 80%;
      }
    }

    @media screen and (max-width: $desktop-breakpoint) {
      margin-left: 0;
    }
  }

  &__cancel {
    display: block;
    margin-top: 20px;
  }

  &--sticky-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px 0 16px;
    width: 100%;
    background: white;
  }
}

#sign-document-container .new-button {
  padding: 10px 0;
  height: auto;
  text-transform: initial;

  svg,
  span {
    vertical-align: middle;
  }

  span {
    padding-left: 10px;
  }
}

#sign-document-container .button--primary {
  svg,
  span {
    vertical-align: middle;
  }

  span {
    padding-left: 10px;
  }
}
