.invoice--list {
  .totals {
    padding: 14px 18px;
    border: 2px solid $grayLightest;
    text-align: center;
    font-size: 16px;
    line-height: 32px;

    @media (min-width: $desktop-breakpoint) {
      position: absolute;
      top: 0;
      right: 0;
    }

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      margin-right: 10px;
    }

    span {
      &:first-of-type {
        padding-right: 16px;
        border-right: 2px solid $grayLightest;
      }

      &:last-of-type {
        padding-left: 16px;
      }
    }
  }

  p:first-of-type {
    font-size: 16px;
    line-height: 16px;
  }

  span {
    &.unpaid,
    &.overdue {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
    }

    &.unpaid {
      background: $yellow;
    }

    &.overdue {
      background: $red;
    }

    &.unpaid ~ span,
    &.overdue ~ span {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  .invoice {
    display: block;
    margin: auto;
    width: 15px;
    height: 39px;
    background: url('~icons/invoice.svg') center no-repeat;
    background-size: contain;
  }

  &._tenant {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @include generate-columns(2);

    .settings {
      position: absolute;
      top: -90px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 23px;
      width: 157px;
      height: 60px;
      border: 2px solid $grayLighter;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 52px;

      svg {
        width: 18px;

        path {
          fill: $gray;
        }
      }

      @media screen and (max-width: $commercial-breakpoint) {
        top: -140px;
      }
    }
  }

  .fixate__position {
    display: flex;

    .export__tabs {
      margin-top: -17px;
    }
  }
}
