.boardroom--payout-requests {
  @include generate-columns(2);

  span {
    &.pending,
    &.approved,
    &.rejected,
    &.processing,
    &.failed,
    &.completed {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
    }

    &.pending {
      background: $yellow;
    }

    &.approved {
      background: $green;
    }

    &.rejected {
      background: $red;
    }

    &.processing {
      background: $blue-300;
    }

    &.failed {
      background: $red;
    }

    &.completed {
      background: $green;
    }

    &.pending~span,
    &.approved~span,
    &.rejected~span,
    &.processing~span,
    &.failed~span,
    &.completed~span {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}

.payout-requests__document {
  display: block;
  margin: auto;
  width: 15px;
  height: 39px;
  background: url($icon_doc) center no-repeat;
  background-size: contain;
}

#payout-request-modal {
  $sticky-footer-offset: 170px;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: auto;

    &:has(+ .footer) {
      padding-bottom: $sticky-footer-offset;
    }
  }

  .header {
    padding: 6px 16px 16px;
    border-bottom: 1px solid $grayLighter;
  }

  .body {
    .flash-message {
      margin: 0 0 12px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        border-bottom: 1px dashed $grayLighter;
      }

      td.reviews {
        display: flex;
        justify-content: flex-end;

        span {
          margin-left: 12px;
        }

        .red {
          color: $red;
        }

        .green {
          color: $green;
        }
      }
    }
  }

  .footer {
    position: sticky;
    top: 100vh;
    width: 100%;
    margin-bottom: 0;
    padding: 72px 34px 34px;
    background: white;
    border-top: 1px solid $grayLighter;
    border-radius: 0 0 12px 12px;

    form {
      display: flex;
      justify-content: space-between;

      input {
        padding: inherit;
        font-family: inherit;
      }

      .checkbox {
        position: absolute;
        top: 20px;
        max-width: 88%;
        width: 88%;

        label {
          font-size: 16px;
        }
      }
    }
  }
}

.payout-request-form {
  display: grid;
  gap: 16px;

  $columns:
    recipient-id,
    board-member,
    recipient-type,
    name,
    first-divider,
    number,
    address,
    zipcode,
    town,
    second-divider,
    bank-name,
    bank-clearing-number,
    bank-account-number,
    third-divider,
    payout-type,
    expense-type-id,
    amount,
    tax-percentage,
    tax-deduction,
    description,
    document,
    description-help,
    submit-action;

  @each $column in $columns {
    &__#{$column} {
      grid-area: #{$column};
    }
  }

  @media (min-width: $desktop-breakpoint-smaller) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'recipient-id'
      'board-member'
      'recipient-type'
      'name'
      'number'
      'address'
      'zipcode'
      'town'
      'bank-name'
      'bank-clearing-number'
      'bank-account-number'
      'payout-type'
      'expense-type-id'
      'amount'
      'tax-percentage'
      'tax-deduction'
      'description'
      'description-help'
      'document'
      'submit-action';
  }

  @media (min-width: $desktop-breakpoint) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'recipient-id recipient-id board-member'
      'recipient-type name number'
      'first-divider first-divider first-divider'
      'address zipcode town'
      'second-divider second-divider second-divider'
      'bank-name bank-clearing-number bank-account-number'
      'third-divider third-divider third-divider'
      'payout-type expense-type-id amount'
      'tax-percentage tax-deduction .'
      'description description description-help'
      'document document document'
      '. . submit-action';
    row-gap: 26px;
  }

  &__text-field {
    width: 100%;
    border: 1px solid $gray;
    border-radius: 6px;
    line-height: 48px;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    text-indent: 16px;
  }

  &__select {
    width: 100%;
    height: 52px;
    border: 1px solid $gray;
    border-radius: 6px;
    background: none;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    text-indent: 16px;
  }

  &__text-area {
    padding: 10px;
    width: 100%;
    height: 200px;
    border: 1px solid $gray;
    border-radius: 6px;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    resize: none;
  }

  hr {
    border: 1px solid $grayLighter;
  }

  .file {
    margin: 0;
  }

  label {
    font-family: $lato_font;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 12px;
  }

  &__recipient-type {
    .radio-table {
      table-layout: auto;
      width: 100%;

      @media (min-width: $desktop-breakpoint) {
        margin-top: -2px;
      }

      thead {
        th {
          line-height: 24px;
          padding: 0px 8px 12px;
          text-align: center;
        }
      }

      tbody {
        td {
          padding: 12px 8px;
          text-align: left;
        }

        .option {
          width: 6em;

          @media (min-width: $desktop-breakpoint) {
            height: 52px;
          }
        }
      }
    }
  }

  &__amount {
    label {
      span {
        display: none;
      }

      &.expense {
        span[data-vat-registered] {
          display: inline;
        }
      }
    }
  }

  &__description-help {
    font-size: 15px;
    text-align: left;

    @media (min-width: $desktop-breakpoint) {
      padding-top: 33px;
    }

    ul {
      li {
        list-style: none;
        margin-bottom: 12px;
      }
    }
  }

  &__document {
    box-sizing: border-box;
    background: #F4F8F8;
    border: 1px dashed #C6D2D8 !important;
    border-radius: 6px;

    &:hover {
      background: #EEF3F2;
      border: 1px dashed #5E8185;
    }

    label {
      font-family: $lato_font;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
    }
  }
}
