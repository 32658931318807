.bank--transactions {
  .balance {
    padding: 14px 18px;
    border: 2px solid $grayLightest;
    text-align: center;
    font-size: 16px;
    line-height: 32px;

    @media (min-width: $desktop-breakpoint) {
      position: absolute;
      top: 0;
      right: 0;
    }

    > * {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  .export__tabs {
    margin-top: -58px;
  }

}
