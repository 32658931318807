.app__container {
  position: relative;
  margin: auto;
  max-width: 980px;
  width: 100%;

  @media(max-width: $mobile-width) {
    overflow: hidden;
  }

  section {
    margin: 0 0 15px 0;

    @media(min-width: $desktop-breakpoint) {
      margin-top: 15px;
    }
  }
}

.section__content {
  padding: 12px;
  max-width: 980px;
  width: 100%;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);

  @media (max-width: $desktop-breakpoint) {
    padding: 24px;
  }
}

.section__content--alert {
  background: $red linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.section__ad {
  margin-bottom: 15px;
  max-width: 980px;
  width: 100%;
  text-align: center;

  img {
    width: auto !important;
  }
}

.component {
  position: relative;
  margin-bottom: 16px;
  color: $gray;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;

  &:last-child {
    margin-bottom: 0;
  }
}

.component--border {
  border: 2px solid $grayLighter;
  border-radius: 4px;
}

.component__header {
  margin: 2px 20px;
  border-bottom: 2px solid $grayLighter;
  color: $gray;
  font-weight: 400;
  font-size: 20px;
  line-height: 44px;
}

.component__header--big {
  margin: 24px auto 0;
  border: none;
  text-align: center;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
}

.component__body {
  margin: 16px 12px;
}

td,
th {
  padding: 8px 0;
  text-align: center;
}

.header__group {
  margin-top: 15px;
  text-align: center;

  .header__title {
    position: relative;
    display: inline-block;

    h1 {
      display: inline-block;
      margin-left: 10px;
      font-weight: 300;
      font-size: 30px;
      @media (min-width: $desktop-breakpoint) {
        font-size: 36px;
      }
    }

    img {
      @media (min-width: $desktop-breakpoint) {
        position: absolute;
        top: 8px;
        left: -25px;
      }
    }
  }

  hr {
    margin-bottom: 12px;
    width: 40px;
    height: 0;
    border: none;
    background: white;
  }
}

.component--nopadding {
  margin: 0 -16px;
}

.component__carousel {
  position: relative;
  margin: 24px -32px auto;

  &.slider-disabled {
    text-align: center;

    .carousel__itemwrapper {
      display: inline-block;
      margin: 0 5px;
      vertical-align: top;
      text-align: left;
    }
  }
}

.carousel__item {
  position: relative;
  display: block;
  margin: auto;
  width: 200px;
  height: 250px;
  border: 2px solid $grayLighter;
  border-radius: 4px;

  &:hover {
    border: 2px solid $grayLight;
  }
}

.carousel .slick-slide {
  margin: 0 10px;
  opacity: 0.4;
  transition: opacity 200ms ease-in;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 100;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $grayLight;
  font-size: 0;
  cursor: pointer;
  transform: translateY(-50%);

  &:hover {
    background-color: $gray;
  }
}

.slick-prev {
  left: -60px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: -4px;
    margin-left: -1px;
    width: 12px;
    height: 2px;
    border-radius: 2px;
    background-color: white;
    content: '';
    /* this single pixel is actually very important. */
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: 4px;
    margin-left: -1px;
    width: 12px;
    height: 2px;
    border-radius: 2px;
    background-color: white;
    content: '';
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
}

.slick-next {
  right: -60px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: -4px;
    margin-left: 1px;
    width: 12px;
    height: 2px;
    border-radius: 2px;
    background-color: white;
    content: '';
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: 4px;
    margin-left: 1px;
    width: 12px;
    height: 2px;
    border-radius: 2px;
    background-color: white;
    content: '';
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}

.rating--none,
.rating--c,
.rating--b,
.rating--a,
.rating--aa,
.rating--aaa {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: white;
  vertical-align: middle;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
}

.rating--aa,
.rating--aaa {
  vertical-align: middle;
  font-size: 16px;

  &::first-letter {
    vertical-align: bottom;
    font-weight: 500;
    font-size: 30px;
    line-height: 60px;
  }
}

.rating--none {
  background-color: $grayLight;
  font-size: 30px;

  &::before {
    content: '?';
  }
}

.rating--c {
  background-color: $red;

  &::before {
    content: 'C';
  }
}

.rating--b {
  background-color: $yellow;

  &::before {
    content: 'B';
  }
}

.rating--a {
  background-color: $green;

  &::before {
    content: 'A';
  }
}

.rating--aa {
  background-color: $green;

  &::before {
    content: 'A+';
  }
}

.rating--aaa {
  background-color: $green;

  &::before {
    content: 'A++';
  }
}

.rating--big {
  margin: 0 auto 24px;
  transform: scale(1.33333);
}

.rating--small {
  margin: -10px;
  transform: scale(0.6666);
}

.component__split--right {
  margin: 0 auto;
  max-width: 410px;
  width: 100%;
}

a.component.component--border {
  display: block;
  padding: 30px 0;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

#premise-map2 {
  width: 100%;
  height: 360px;
}

@media (max-width: 767px) {
  .component {
    &--informations {
      font-size: 16px;
      line-height: 1.5;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .buyanalysis__example {
    margin: 16px 0 0 0;
  }
}

.picture-block {
  .demo {
    position: relative;

    img {
      margin: auto;
      max-width: 320px;
      width: 100%;
      height: auto;
    }
  }
}

@media (min-width: $desktop-breakpoint) {
  .section__content {
    padding: 24px;
  }

  .component {
    margin-bottom: 24px;
  }

  .component__body {
    margin: 0;
  }

  #module-start {
    .component__grid {
      margin-bottom: 0;
    }
  }

  #module-lagenheter h1 {
    margin-bottom: 24px;
  }

  .monthlyfee--graph {
    float: left;
    margin: initial;
    margin-top: -15px;
    padding-bottom: 24px;
    width: 410px;
    height: 230px;
  }

  .component__header--big {
    margin-bottom: 24px;
  }

  .rating__information {
    float: right;
    margin-right: 24px;
    font-size: 16px;
  }

  .rating__information {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }
}

@media(max-width: 1260px) {
  .app__content {
    display: block;
    margin: auto;
  }
}
