.boardroom--parking {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @include generate-columns(20);

  .export {
    position: absolute;
    right: 0;
    margin-top: -60px;
  }
}
