.surcharges-history {
  @include generate-columns(2);

  &__receipt {
    display: block;
    margin: auto;
    width: 15px;
    height: 39px;
    background: url($icon_doc) center no-repeat;
    background-size: contain;
  }
}

.surcharge-form {
  display: grid;
  gap: 16px;

  @each $column in property-label property first-divider product second-divider kind period-label period description-label description fee submit-button {
    &__#{$column} {
      grid-area: #{$column};
    }
  }

  @media (min-width: $desktop-breakpoint-smaller) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'property-label'
      'property'
      'product'
      'kind'
      'period-label'
      'period'
      'description-label'
      'description'
      'fee'
      'submit-button';
  }

  @media (min-width: $desktop-breakpoint) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'property-label . .'
      'property .  .'
      'first-divider first-divider first-divider'
      'product . .'
      'second-divider second-divider second-divider'
      'kind . .'
      'period-label . .'
      'period . .'
      'description-label . .'
      'description description .'
      'fee . .'
      'submit-button . .';
  }

  .surcharge-form__text-field {
    width: 100%;
    border: 1px solid $gray;
    border-radius: 7px;
    line-height: 48px;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    text-indent: 16px;
  }

  .surcharge-form__select {
    width: 100%;
    height: 52px;
    border: 1px solid $gray;
    border-radius: 7px;
    background: none;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    text-indent: 16px;
  }

  .surcharge-form__text-area {
    padding: 10px;
    width: 100%;
    height: 200px;
    border: 1px solid $gray;
    border-radius: 7px;
    font-family: $lato_font;
    font-weight: 400;
    font-size: 16px;
    resize: none;
  }

  hr {
    border: 1px solid $grayLighter;
  }

  .surcharge-form__property-label,
  .surcharge-form__period-label,
  .surcharge-form__description-label {
    font-family: $lato_font;
    font-weight: bold;
  }

  .autoComplete_list {
    z-index: 90;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: white;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    color: $gray;

    li {
      position: relative;
      padding: 0 16px;
      min-height: 48px;
      line-height: 48px;
      cursor: pointer;

      &:after {
        display: block;
        height: 1px;
        background: $grayLightest;
        content: '';
      }

      &:last-child:after {
        display: none;
      }

      &:hover {
        background: $grayLightest;
      }

      &.autoComplete_selected {
        background: $grayLightest;
      }

      em {
        color: black;
      }
    }
  }
}