.user--settings {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @include generate-columns(2);

  h5 {
    &:nth-of-type(1) {
      margin-top: 0;
    }

    &:not(:nth-of-type(1)) {
      padding-top: 24px;
      border-top: 1px solid $grayLightest;
    }
  }

  @media(max-width: $lg) {
    .heading .details div {
      display: block;

      ul {
        width: 100%;
      }
    }
  }
}

.user--invoices {
  padding: 26px;
  border: 1px solid $grayLightest;
  border-radius: 4px;

  @include generate-radio-columns(4, po, nested-tabs);

  .content > .tab {
    padding: 20px 16px;
    border: 1px solid $grayLightest;
    border-radius: 4px;
    background: $whiteOut;

    select {
      width: 100%;
    }

    .display-table {
      display: table;
    }
  }
}
