.section--forsaljningar {
  text-align: center;

  h3 {
    margin-bottom: 16px;
  }

  table {
    min-width: 640px;
  }

  .organization--sales {
    margin: 16px 0 24px;
  }

  .neighbour--sales {
    margin-bottom: 32px;
  }

  .hemnetitem__text {
    text-align: left;
  }

  /* Needed to keep the carousel as it is on production */
  .dark.bold {
    font-weight: 700;
  }

  @media screen and (min-width: $md) and (max-width: $lg) {
    .evaluate {
      .grid.__gap.__divider {
        display: block !important;
      }
    }
  }
}

.evaluate {
  margin-top: 32px;
  padding-top: 24px;

  h5 {
    margin-bottom: 8px;
  }

  .body {
    text-align: center;
  }

  @media screen and (max-width: calc(#{$md} - 1px)) {
    .grid > div:first-child {
      margin-bottom: 32px;
    }
  }
}

.hemnetitem {
  &__image {
    width: 100%;
    height: 165px;
  }

  &__text {
    margin-bottom: 3px;
    padding: 11px;
    color: $grayDarker;
    font-size: 16px;
    line-height: 18px;
  }

  &__squareperm2 {
    margin-left: -3px;
    color: $gray;
    font-size: 11px;
  }

  &__address {
    overflow: hidden;
    margin-top: 6px;
    width: 170px;
    color: $gray;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
}

.sales--history {
  h3 {
    margin-bottom: 16px;
  }
}
