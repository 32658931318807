.read-about-plus {
  margin-bottom: 10px;
  color: $gray;
  line-height: 1.4;
}

@media (min-width: $desktop-breakpoint) {
  .profile-organization-menu,
  .profile-organization-content.with-menu {
    display: inline-block;
    vertical-align: top;
  }

  .profile-organization-content.with-menu {
    margin-left: 16px;
    width: calc(unquote("100% - 296px"));
  }
}

.profile-organization-menu {
  max-width: 280px;

  ol {
    counter-reset: item;

    span,
    a {
      color: $purple;
    }

    li {
      display: block;
      margin-bottom: 20px;

      &::before {
        margin-right: 5px;
        color: $purple;
        content: counters(item, '.') ' ';
        counter-increment: item;
        font-weight: 700;
      }

      .profile-organizations-menu-active {
        position: relative;
        z-index: 1;

        &::before {
          position: absolute;
          bottom: -5px;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 2px;
          background: $purple;
          content: '';
          transition: all 0.3s ease-in-out;
        }
      }

      a {
        cursor: pointer;

        &::after {
          opacity: 0;
          transform: translateY(-5px);
        }

        &:hover {
          &::after {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      .js-first-li,
      &.js-first-li a {
        font-weight: 600;
        cursor: pointer;
      }

      ol {
        padding-left: 2rem;

        li {
          position: relative;
          font-weight: 400;

          &::before {
            font-weight: 400;
          }

          &:first-of-type {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.profile-organization-content {
  .profile-form-header {
    margin-bottom: 1.5rem;
    line-height: 1.4;

    h3 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 18px;

      &.description-heading {
        font-size: 22px;
      }
    }

    p {
      margin-bottom: 10px;
      color: $gray;
    }
  }

  .profile-organizations-form-error {
    margin: 1.5em 0;
    color: $red;
    font-size: 1rem;

    ul {
      margin-top: 0.5em;
      padding-left: 20px;
      counter-reset: item;

      li {
        list-style-type: disc;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.6;
      }
    }
  }

  .profile-form,
  .service-form {
    max-width: $form_width;
  }

  .file-form {
    margin-bottom: 5em;

    .form-group {
      &.file {
        padding: 4.5rem 0.8rem;
      }

      &.number {
        max-width: 260px;
      }
    }
  }

  .form-group {
    //textarea under edit organization description
    &.textarea {
      .profile-remaining-chars {
        position: absolute;
        right: 10px;
        bottom: -10px;
        z-index: 2;
        font-weight: 400;
        font-size: 0.875rem;
      }

      .js-text-remain {
        font-weight: 400;

        &.red {
          color: $red;
        }

        &.green {
          color: $green;
        }
      }

      //exampel description text
      .profile-example {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -49;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        transition: all 0.3s ease-in-out;

        &.show {
          z-index: 1000;
          background: rgba(0, 0, 0, 0.4);
        }

        &-hidden-box {
          position: fixed;
          top: 50%;
          right: initial;
          left: 50%;
          z-index: -50;
          visibility: hidden;
          padding: 20px;
          min-height: 320px;
          max-width: 670px;
          width: 100%;
          background: white;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: all 0.3s ease-in-out;
          transform: translate(-50%, -50%) translate3d(0, 30px, 0);

          &.show {
            z-index: 50;
            visibility: visible;
            opacity: 1;
            transition-delay: 0.3s;
            transform: translate(-50%, -50%) translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  .example-modal__button {
    font-size: 0.875rem;
  }

  .profile-contact-form {
    > div {
      &:nth-child(2) {
        margin-top: 1.5rem;
      }
    }
  }

  //edit contacts
  .edit_profile_organizations_contact_info {
    margin-bottom: 5em;
    max-width: $form_width;
    width: 100%;

    .form-group-select-section {
      position: relative;
      padding: 20px;
      border: 1px solid $gray;

      &:not(:nth-last-of-type(2)) {
        margin-bottom: 40px;
      }

      .form-group {
        margin: 25px 0;

        &.text.name {
          margin: 0;
          margin-bottom: 15px;
          padding-bottom: 15px;

          input {
            padding: 0;
            padding-left: 0;
            border-bottom: none;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6;
          }

          span {
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
            font-size: 0.875rem;
          }
        }

        &.selects {
          margin-top: 0;
        }

        &.text {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .form-footer {
    margin-top: 2em;
    width: 100%;

    .submit {
      margin: 0;

      input {
        padding: 8px;
        min-width: 120px;
        width: auto;
        border: 2px solid $purple;
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.875rem;
        cursor: pointer;
        transition: all 150ms ease;

        &:hover {
          background: $purple;
          color: white;
        }
      }
    }

    span {
      display: block;
      float: left;
      padding: 16px 8px;
      width: 50%;
      text-align: right;

      a {
        padding: 0;
        color: $purple;
      }
    }
  }

  .profile-form-history {
    margin-top: 4em;
    width: 100%;

    span {
      display: inline-block;
      margin-bottom: 20px;
      padding: 10px 0;
      border-bottom: 1px solid $grayLight;
    }
  }
}

.profile-flash-message {
  position: relative;
  margin: 28px 0;
  padding: 15px;
  border: 1px solid $grayLightest;
  border-left: 12px solid $green;
  border-radius: 4px;
  font-size: 14px;

  &.alert {
    border-left: 12px solid $yellow;
  }

  li {
    padding: 4px 0;
  }
}

//form saving inputs
.profile-table-container {
  width: 100%;
}

.profile-table {
  min-width: 500px;
  width: 100%;
  table-layout: auto;

  &.annual-table {
    min-width: 270px;
  }

  &.other-table {
    min-width: 550px;
  }

  th,
  td {
    padding: 12px 8px;
    text-align: left;
  }

  thead {
    background: lighten($lightBlue, 40%);
    font-weight: 400;

    th {
      &.short-label {
        text-align: right;
      }

      &.file-year {
        width: 70px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 2px solid lighten($lightBlue, 40%);
      font-weight: 400;

      td {
        a {
          color: $purple;

          &:hover {
            text-decoration: underline;
          }
        }

        &.file-download {
          position: relative;
          width: 70px;
          text-align: right;

          a {
            font-size: 0;

            &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 18px;
              height: 18px;
              background-image: url($icon_download);
              background-size: contain;
              background-repeat: no-repeat;
              content: '';
              transform: translate(-50%, -50%);
            }
          }
        }

        &.delete-file {
          position: relative;
          width: 15px;
          text-align: right;
          font-size: 0;
          cursor: pointer;

          .profile-tool-tip {
            min-width: 122px;
            @media (max-width: $desktop-breakpoint) {
              display: none !important;
            }
          }

          a {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 15px;
            height: 15px;
            font-size: 0;
            transition: all 0.2s ease-in-out;
            transform: translate(-50%, -50%);

            &::before,
            &::after {
              position: absolute;
              top: 50%;
              right: 0;
              width: 15px;
              height: 1px;
              background: black;
              content: '';
              transition: background 0.1s ease-in-out;
              transform: translateY(-50%);
            }

            &::before {
              transform: translateY(-50%) rotate(45deg);
            }

            &::after {
              transform: translateY(-50%) rotate(-45deg);
            }

            &:hover {
              transform: translate(-50%, -50%) rotate(-90deg);

              &::before,
              &::after {
                background: $purple;
              }
            }
          }
        }

        &.profile-start-image {
          position: relative;
          //unchecked checkbox
          a {
            font-size: 0;

            &::after {
              position: absolute;
              top: 50%;
              left: 30px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background: $grayLighter;
              content: '';
              transition: background 0.1s ease-in-out;
              transform: translateY(-50%);
            }

            &:hover {
              &::after {
                background: $gray;
              }
            }

            &.chosen {
              &::after {
                background: $purple;
              }
            }
          }
        }
      }
    }
  }

  &.profile-radio-table {
    thead {
      th {
        text-align: center;
      }
    }

    tbody {
      .option {
        width: 6em;
      }
    }
  }
}

.subscription-table {
  td:last-of-type {
    text-align: right;
  }
}

//profile organization description
.profile-description-caption {
  margin-top: 2.5rem;
  max-width: $form_width;
  width: 100%;
  font-weight: 700;
  font-size: 1.1rem;
}

.profile-description {
  line-height: 1.6;
}

.profile-description-exampel {
  text-align: left;
}

@media (max-width: 1024px) {
  .profile-organization-menu {
    max-width: initial;
  }
  .profile-organization-content {
    padding-top: 2em;
    width: 100%;

    .profile-form-header {
      width: 100%;
    }

    .edit_profile_organizations_contact_info,
    .profile-form,
    .service-form {
      width: 100%;
    }

    .profile-description-caption {
      margin: auto;
    }
  }
}

@media (max-width: 768px) {
  .profile-organization-container {
    .profile-organization-menu {
      ol {
        text-align: left;

        li {
          margin-bottom: 30px;

          ol {
            padding-left: 0;

            li {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .profile-organization-content {
      padding-top: 30px;

      .profile-form,
      .service-form,
      .edit_profile_organizations_contact_info {
        margin-bottom: 2rem;
      }

      .form-group {
        width: 100%;

        &.file {
          padding: 5rem 0;
          width: 100%;
        }

        &.text,
        &.number {
          input {
            padding-top: 30px;
            padding-bottom: 10px;
          }
        }
      }

      .form-footer {
        .submit {
          margin-bottom: 20px;
          text-align: center;

          input {
            min-width: 220px;
          }
        }

        span {
          float: none;
          width: 100%;
          text-align: center;
        }
      }

      .edit_profile_organizations_contact_info {
        .form-group-select-section {
          padding: 10px;

          .form-group {
            &.text.name {
              input {
                font-size: 1.2rem;
              }

              span {
                font-size: 0.75rem;
              }
            }

            label,
            input {
              font-size: 1rem;
            }
          }
        }
      }

      .profile-form-history {
        margin-top: 2rem;
        text-align: center;
      }
    }
  }
  .profile-table {
    min-width: initial;
    width: 100%;

    .option {
      width: 6em;

      input[type='radio'] {
        padding: 15px;
      }
    }
  }
}

@media (max-width: 680px) {
  .profile-description-caption {
    margin-top: 2.5rem;
  }

  .profile-table-container {
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      background: linear-gradient(
                      to right,
                      rgba(255, 255, 255, 0),
                      rgba(255, 255, 255, 1)
      );
      content: '';
    }
  }
}
