.section--info_bilder {
  text-align: center;

  hgroup svg {
    margin-top: 10px;
  }

  h3 {
    margin-bottom: 16px;
  }

  h4 {
    margin-bottom: 16px;
  }

  p.small {
    margin: 0;
    font-weight: 700;
  }

  p {
    margin-bottom: 24px;
    text-align: left;
  }

  .gallery a[class^="button--"] {
    margin-top: 24px;
  }
}

.article--info-img {
  position: relative;
  margin: 0 auto !important;
  max-width: 688px !important;
  text-align: left;
}

.informationsgradient {
  position: relative;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 150px;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
    content: "";
  }
}

.component--informations {
  overflow: hidden;
  max-height: 300px;
  line-height: 25px;
}

@keyframes readmore {
  0% {
    max-height: 300px;
  }
  99% {
    max-height: 1000px;
  }
  100% {
    max-height: initial;
  }
}

.gallery {
  position: relative;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  &:last-child {
    margin-bottom: 0;
  }
}

.empty-state--photos {
  margin: 24px auto;
  width: 100%;
  height: 200px;
}

.informations--fullview {
  height: initial;
  animation: readmore 0.4s forwards;
}

.informations-images-modal__button {
  display: block;
  width: 100%;
  height: 300px;
}

.component__horizontal {
  display: flex;
  flex-direction: column;

  * {
    margin-bottom: 8px;
  }

  .informations-images__addimages {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center;

    p {
      margin-left: 8px;
      color: $grayDarker;
    }
  }

  @media (min-width: $md) {
    flex-direction: row;
    margin-bottom: 8px;
    * {
      margin-bottom: 0;
    }
    * + * {
      margin-left: 8px;
    }

    .informations-images-modal__button {
      flex: 1;
    }
  }
}

.plussign {
  margin-bottom: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $grayLight;
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
}
