.section--omradet {
  color: $grayDarkest;

  .neighbordata__content {
    margin: 0;
    padding: 0;
  }

  .neighbours {
    text-align: center;
  }

  hgroup svg {
    margin-top: 12px;
  }

  .map {
    position: relative;
    z-index: 5;
    height: 360px;
  }

  .table--basic th {
    padding: 8px 0;
    text-align: left;
    font-weight: 400;
  }

  .table--basic tr:nth-of-type(1) th {
    padding-top: 4px;
  }

  .table--basic td {
    padding: 8px 0;
    text-align: right;
    font-weight: 400;
  }

  div[class^='table--'] tr {
    border-bottom: 1px solid $grayLighter;
  }

  .area--data {
    margin: 24px auto;
  }

  .grade {
    margin: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .section--omradet .area--data {
    margin-top: 0;
  }
}

.section--omradet .tabs--content {
  h3 {
    margin-bottom: 8px;
  }

  table {
    margin-bottom: 24px;
  }

  .content {
    display: none;

    &.__active {
      display: block;
    }
  }
}

.component--omradetmap {
  width: 100%;
  height: 360px;
  position: relative;
}

.component--placeholder--image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.component--placeholder--button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
}

.button--fade {
  height: 40px;
  padding-right: 35px !important;
  padding-left: 35px !important;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 9999px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  transition: background-color 0.2s, color 0.15s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
}

.orgitem__wrapper {
  width: 100%;
  height: 190px;
  border-bottom: 2px solid $grayLighter;
  text-align: center;

  @media (min-width: $desktop-breakpoint) {
    width: 200px;
  }
}

.carousel__item:hover .orgitem__wrapper {
  border-bottom: 2px solid $grayLight;
}

.orgitem__name {
  padding: 10px;
  height: 75px; /* force height on company name so all ratings below are at same height */
  font-weight: 400;
  font-size: 20px;
}

.orgitem__image {
  position: relative;
  width: 100%;
  height: 190px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  @media (min-width: $desktop-breakpoint) {
    width: 200px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    color: white;
    text-align: center;
    font-size: 24px;
    transform: translateY(-50%) translateX(-50%);
  }
}

.orgitem__text {
  padding: 10px 0 10px 10px;
  width: 135px;
  color: $gray;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.orgitem__text_container {
  display: flex;

  .bold {
    margin-left: 5px;
  }
}

.orgitem__text--faded {
  overflow: hidden;
  margin-left: 5px;
  background: -webkit-linear-gradient(45deg, $gray 80%, white);
  -webkit-background-clip: text;
  content: '';
  white-space: nowrap;

  -webkit-text-fill-color: transparent;
}

.orgitem__rating {
  float: right;
  margin: 10px 15px 15px 0;
}

.neighbordata__tab--selected {
  border: 2px solid lighten($lightBlue, 40%);
  background-color: lighten($lightBlue, 40%);

  &::after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    width: 0;
    height: 0;
    border-width: 8px 12px 0 12px;
    border-style: solid;
    border-color: lighten($lightBlue, 40%) transparent transparent transparent;
    content: '';
    transform: translateX(-50%);
  }
}

.neighbordata__content {
  margin-bottom: 30px;
  padding: 0 15px;

  @media (min-width: $desktop-breakpoint) {
    padding: 0 145px;
  }
}

.table__header {
  margin-top: 30px;
  text-align: left;
}

.neighbordata__content--kommunikationer,
.neighbordata__content--rekreation,
.neighbordata__content--skola,
.neighbordata__content--ovrigt,
.neighbordata__content--vatten {
  display: none;
}

.neighbordata__content--visible {
  display: block;
}
