span {
  &.icon-plus {
    background-image: url($icon_plus);
  }

  &.icon-minus {
    background-image: url($icon_minus);
  }

  &.icon-plus,
  &.icon-minus {
    display: inline-block;
    margin-right: 10px;
    width: 14px;
    height: 14px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
    }
  }
}

[data-controller='board-results'] {
  .tabulator-tree-level-1 > :first-child {
    padding-left: 42px;
  }

  .tabulator-tree-level-2 > :first-child {
    padding-left: 70px;
  }

  .tabulator-tree-level-3 > :first-child {
    padding-left: 100px;
  }

  .fixate__right {
    display: flex;

    .export {
      position: absolute;
      right: 0;

      .icon-download {
        display: inline-block;
        margin: 15px 0;
        width: 15.94px;
        height: 16px;
        background-image: url($icon_export_xls);
        background-position: center;
        vertical-align: middle;
        opacity: 55%;

        &:hover {
          opacity: 100%;
        }
      }
    }
  }

  @media screen and (max-width: calc(#{$md} - 1px)) {
    .fixate-right {
      justify-content: flex-start;
      margin-top: 70px;

      .date_picker {
        div:first-of-type {
          width: 70px;
        }

        input {
          width: 115px;
        }
      }
    }
  }

  .tabulator-row {
    .tabulator-cell {
      .tabulator-data-tree-control {
        position: relative;
        top: -2px;
        vertical-align: middle;
      }
    }
  }

  .tabulator-header .rightAlignedHeader {
    &:hover {
      cursor: pointer;
    }
  }

  .board-rooms--await-loading {
    position: absolute;
    left: 42%;
    z-index: 1;
    display: block;
    padding: 10px;
    background: #ffffff;
  }

  .main-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 9px 0;
    padding-left: 10px;
    width: 148px;
    height: 32px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);

    label {
      opacity: 0.75;
    }

    .checkbox {
      position: relative;
      margin-right: 9px;
      width: 36px;
      height: 20px;
      border-radius: 2px;
      background: rgba(0, 0, 0, 0.2);

      &::after {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border-radius: 1px;
        background: $lightPurple;
        content: '';
        transition: all 0.3s;
      }
    }

    &.active {
      .checkbox {
        &::after {
          left: 18px;
          background: #e3daea;;
        }
      }

      label {
        opacity: 1;
      }
    }

    input {
      position: absolute;
      left: 0;
      z-index: 5;
      margin: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
