.organization-subscriptions {
  .subscription-item {
    position: relative;
    margin-bottom: 32px;
    padding: 24px;
    border: 1px solid lighten($lightBlue, 40%);
    border-radius: 3px;
    border-radius: 4px;
    background: white;
    box-shadow: 0 5px 20px lighten($lightBlue, 40%);
    opacity: 1;
    transition: visibility 0s 1s, opacity 1s linear;

    &.pre-hidden-animation {
      opacity: 0;
    }

    .cross-button {
      position: absolute;
      top: 0;
      right: 0;
    }

    h3 {
      display: inline-block;
      padding-left: 8px;
      font-weight: bold;
      font-size: 18px;
    }

    span {
      float: left;
      font-weight: normal;
      font-size: 16px;
    }
  }

  .subscription-link {
    margin-left: 10px;
  }

  .subscription-list {
    margin-top: 24px;

    li {
      height: 48px;
      border-bottom: 1px solid lighten($lightBlue, 40%);
      text-align: right;
      line-height: 48px;

      &:last-child {
        border: none;
      }
    }
  }
}
