.arrow-up {
  float: right;
  margin-top: 16px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  border-left: 5px solid transparent;
}

.arrow-down {
  float: right;
  margin-top: 16px;
  width: 0;
  height: 0;
  border-top: 5px solid black;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.subsection {
  margin-left: 15px;

  .active-subsection {
    width: 94%;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.5);
  }
}

[data-controller='board-finance-balance'],
[data-controller='board-finance-result'] {
  span {
    display: inline-block;
    margin-right: 10px;
    width: 14px;
    height: 14px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
    }
  }

  .icon-plus {
    background-image: url($icon_plus);
  }

  .icon-minus {
    background-image: url($icon_minus);
  }

  h4 {
    margin-bottom: -55px;
  }

  .fixate__right {
    display: flex;

    .export__tabs {
      margin-top: 0;
    }
  }

  .date_picker {
    width: 260px;

    select {
      border: none;
      background-color: inherit;
      text-align: center;
    }
  }

  .tabulator-row {
    .tabulator-cell {
      .tabulator-data-tree-control {
        position: relative;
        top: -2px;
        vertical-align: middle;
      }
    }
  }

  .tabulator-header .rightAlignedHeader {
    &:hover {
      cursor: not-allowed;
    }
  }

  .board-rooms--await-loading {
    position: absolute;
    left: 42%;
    z-index: 1;
    display: block;
    padding: 10px;
    background: #ffffff;
  }

  .tabulator-tree-level-0 {
    font-weight: bold;
  }

  .tabulator-tree-level-1 {
    .tabulator-cell:first-child {
      padding-left: 51px !important;
    }
  }

  .tabulator-tree-level-2 {
    .tabulator-cell:first-child {
      padding-left: 86px !important;
    }
  }

  .tabulator-tree-level-3 {
    .tabulator-cell:first-child {
      padding-left: 121px !important;
    }
  }
}
