@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}

//MIXINS
@mixin center() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerX() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY() {
  top: 50%;
  transform: translateY(-50%);
}

@keyframes modal-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
  }
  100% {
    display: none;
    height: 0;
    opacity: 0;
  }
}

@mixin generate-columns($n, $tabname: tab, $tabgroup: tabs, $i: 1) {
  @if $i <= $n {
    input[name="#{$tabgroup}"] {
      &##{$tabname}-#{$i}:checked {
        ~ .content > .tab:nth-of-type(#{$i}) {
          display: inline-block;
        }

        ~ .tabs > label:nth-child(#{$i}) {
          border-bottom: 3px solid $green;
          color: $grayDarkest;
        }
      }
    }

    @include generate-columns($n, $tabname, $tabgroup, ($i + 1));
  }
}

@mixin generate-radio-columns($n, $tabname: tab, $tabgroup: tabs, $i: 1) {
  @if $i <= $n {
    input[name="#{$tabgroup}"] {
      &##{$tabname}-#{$i}:checked {
        ~ .content > .tab:nth-of-type(#{$i}) {
          display: inline-block;
        }

        ~ .tabs label:nth-child(#{$i}) {
          &:before {
            border: 5px solid $purple;
          }
        }
      }
    }
    @include generate-radio-columns($n, $tabname, $tabgroup, ($i + 1));
  }
}
