.customer--contract {
  @include generate-columns(2);

  .date_picker {
    box-shadow: none;
    width: 340px;
    border: 1px solid #eee;
    border-radius: 4px;

    div:first-of-type {
      font-size: 14px;
    }

    input {
      border-bottom: 1px solid #eee;

      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .export__tabs {
    margin-top: -30px;
  }

  .tabulator {
    margin-top: 16px;
  }

  .tabulator-footer-contents {
    padding: 0 !important;
    display: block !important;
  }

  .footer {
    clear: both;
    position: relative;
    top: -12px;
    right: 0;
    box-sizing: border-box;
    width: 872px;
    height: 0px;
    border-right: 1px solid $grayLighter;
    border-bottom: 1px solid $grayLighter;
    border-left: 1px solid $grayLighter;
  }

  .tabulator-tableholder {
    overflow-y: hidden !important;
    border-bottom: none !important;
  }

  .tabulator-paginator {
    float: right;
  }
}
