.section--styrelse h5:not(.second) {
  margin-bottom: 24px;
}

.section--styrelse {
  .table--basic th {
    padding: 8px 0;
    text-align: left;
    font-weight: 400;
  }

  .table--basic tr:nth-of-type(1) th {
    padding-top: 4px;
  }

  .table--basic td {
    padding: 8px 0;
    text-align: right;
    font-weight: 400;
  }

  div[class^='table--'] tr {
    border-bottom: 1px solid $grayLighter;
  }
}

.styrelse-donutchart--gender {
  position: relative;
}

.styrelse-chart--gender {
  position: relative;
  margin: auto;
  width: 100%;
  height: 300px;

  @media(min-width: $desktop-breakpoint) {
    zoom: 1;
  }
}

.styrelse__chartoverlay--gender {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 1.1;
  transform: translate(-50%, -50%) !important;

  p {
    font-size: 18px;
  }

  #gender-chart--average-age {
    color: $grayDarker;
    font-weight: 500;
    font-size: 48px;
  }
}
