.page--room {
  .box-shortcuts {
    justify-content: left;
  }

  .profile-block:nth-child(6) {
    .box-shortcuts {
      li:nth-child(4) {
        svg {
          path:nth-child(3) {
            fill: white !important;
          }
        }
      }
    }
  }

  .profile-block:nth-child(2) {
    .box-shortcuts {
      li.edit_tenant_information {
        svg {
          path:nth-child(3) {
            fill: white !important;
          }
        }
      }
    }
  }

  .breadcrumbs {
    li {
      display: inline-block;
      color: $gray;
      font-size: 14px;
      line-height: 14px;

      a {
        color: $gray;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-of-type {
        a {
          color: $grayDarkest;
        }
      }
    }
  }

  .property-info {
    font-size: 15px;

    li {
      width: 224px;

      span {
        line-height: 24px;

        &:first-of-type {
          font-weight: 300;
        }
      }
    }
  }
}
