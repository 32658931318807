#invoice-delivery {
  .popup-module {
    width: 560px;
    height: 680px;
  }

  ul {
    li {
      color: $grayDarker;
      font-weight: 600;
      line-height: 15px;
    }
  }

  .form-group {
    &.text,
    &.number {
      margin: 20px 0;
    }
  }

  .form-group {
    &.radio {
      margin-top: 0;
      margin-bottom: 0;

      label {
        padding: 4px;
        padding-left: 24px;
        font-weight: bold;
        font-size: 16px;
      }

      .check {
        left: 8px;
      }
    }
  }

  fieldset {
    padding: 24px;
    border: 1px solid $grayLightest;
    border-radius: 3px;

    &[data-tab="email"] {
      input {
        margin-bottom: 0;
      }
    }

    .input-wrapper {
      margin-right: 0;

      input:disabled {
        cursor: not-allowed;
      }
    }

    div {
      max-width: 215px;

      &:nth-of-type(1) {
        color: $gray;
        font-size: 14px;
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        max-width: 100%;
      }
    }

    p.note {
      color: $gray;
      font-size: 12px;
      line-height: 12px;
    }
  }

  div[data-target="invoice-delivery.formSuccess"],
  div[data-target="invoice-delivery.formError"] {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    svg {
      margin-right: auto;
      margin-bottom: 24px;
      margin-left: auto;
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 24px;
    }
  }

  .margin-b-96 {
    display: none;
  }

  .sticky-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 20px 0;
    width: 100%;
    background: white;

    input[type="submit"] {
      display: block;
      margin: auto;
      max-width: 220px;
    }
  }
}

@media screen and (max-width: $lg) {
  #invoice-delivery {
    .popup-module {
      height: 100%;
    }

    .margin-b-96 {
      display: block;
    }

    .form-group.margin-l-176 {
      margin-left: 0;
    }

    fieldset {
      div {
        max-width: 120px;
      }
    }
  }
}
