/* Page: Organization */

.page--organization {
  main {
    section {
      background: white;

      &:not(.section--summering) {
        margin-top: 24px;
        padding: 32px 0 16px;
      }

      @media screen and (min-width: $lg) {
        border-radius: 3px;
      }
    }

    div[class*='table--'] {
      font-weight: 400;

      thead th {
        font-weight: 700 !important;
      }

      tbody {
        td {
          font-weight: 400;
        }
      }
    }

    p:not(.text--bredband) {
      margin: 0 auto;
    }

    article {
      text-align: left;

      p {
        margin-bottom: 24px;
      }
    }

    hr {
      margin: 24px auto;
      height: 0;
      border: 0;
      border-bottom: 1px solid $grayLighter;
    }

    hgroup {
      display: flex;
      align-items: center;
      justify-content: center;

      h1,
      h2 {
        line-height: 1;
      }

      h3,
      h4 {
        font-weight: 700;
      }

      span {
        display: flex;
        margin-right: 16px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: $purple;
        text-align: center;
      }

      svg {
        margin: auto;
      }

      path {
        fill: white;
      }
    }

    .collaborator {
      margin-top: 24px;
      color: $gray;
      font-size: 14px;

      img,
      svg {
        margin-left: 6px;
        padding-top: 4px;
        width: auto;
        height: 20px;
        vertical-align: bottom;
      }
    }

    .usp {
      margin-top: 32px;
      padding-top: 24px;
      border-top: 1px solid $grayLighter;
      text-align: center;

      a[class^='button--'],
      button[class^='button--'] {
        margin: 16px auto 0;
      }

      h3 {
        margin: 0 auto 8px;
      }
    }

    /* Used on top of page--organisation to reveal full document */
    .button-top--reveal {
      display: block;
      padding: 16px;
      border: 2px solid $grayLighter;
      border-radius: 3px;
      background: white;
      color: $purple;
      text-align: center;
      font-weight: 700;

      &:hover {
        border-color: $grayLight;
      }
    }
  }
}
