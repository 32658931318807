[data-controller="org-dashboard"] {
  .dashboard--blocks {
    z-index: 1 !important;

    @media screen and (max-width: calc(#{$lg} - 1px)) {
      margin-top: 24px;
    }

    @media screen and (max-width: $md) {
      margin-top: 0;
    }
  }

  .heading {
    span.black {
      color: black;
    }

    ul {
      position: relative;
      z-index: 999 !important;
      overflow: initial;

      li {
        &:last-of-type {
          position: relative;

          a {
            padding-right: 16px;
            color: $purple;
            text-decoration: underline;
            cursor: pointer;

            span {
              position: absolute;
              top: 9px;
              display: inline-block;
              margin-left: 4px;
              width: 7px;
              height: 2px;
              background-color: $purple;
              transition: all 0.2s ease;

              &:first-of-type {
                transform: scale(0.9) rotate(45deg);
              }

              &:last-of-type {
                margin-left: 7px;
                transform: scale(0.9) rotate(-45deg);
              }
            }

            &.active {
              span {
                &:first-of-type {
                  transform: scale(0.9) rotate(-45deg);
                }

                &:last-of-type {
                  transform: scale(0.9) rotate(45deg);
                }
              }
            }
          }

          .toggle {
            position: absolute;
            display: block;
            visibility: hidden;
            margin-top: 3px;
            padding: 9px 12px;
            min-width: 200px;
            border-radius: 1px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            color: black;
            opacity: 0;
            transition: visibility 0s linear 0.33s, opacity 0.33s linear;
          }

          &.__active {
            .toggle {
              visibility: visible;
              background-color: white;
              opacity: 1;
              transition-delay: 0s;
            }

            a {
              span {
                &:first-of-type {
                  transform: scale(0.9) rotate(-45deg);
                }

                &:last-of-type {
                  transform: scale(0.9) rotate(45deg);
                }
              }
            }
          }
        }
      }
    }
  }

  .page--dashboard {
    section {
      position: relative;
      padding: 36px 16px;
      width: 100%;
      border-radius: 4px;
      background: white;

      @media screen and (min-width: $lg) {
        margin-left: 24px;
        padding: 48px;
      }
    }

    p {
      color: $grayDarkest;

      &.invoice {
        margin: 16px 8.5px;
        color: $gray;
        font-size: 14px;
        line-height: 20px;

        span {
          color: $grayDarkest;
        }
      }
    }

    [class^="button--"].__sm {
      min-width: 144px;
      font-size: 13px;
    }

    .profile-block-header--image {
      flex: 1.4;
      margin-right: 28px;
      height: 124px;
      border: 1px solid $grayLightest;
      border-radius: 3px;
      background-color: $grayLightest;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (max-width: $md) {
        flex: auto;
      }
    }

    .brf--info {
      flex: 1;

      h4 {
        margin: 0 0 0 8.5px;
        font-weight: 700;
        font-size: 16px;
      }

      @media screen and (max-width: $md) {
        flex: none;

        .flex {
          flex-direction: row;
        }
      }
    }

    // Overrides
    .column .column:first-of-type {
      margin-top: 0;
    }

    @media screen and (min-width: $lg) {
      .column .column .button--primary {
        float: right;
      }
    }
  }

  .dashboard--blocks {
    display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0;

    div {
      position: relative;
      overflow: hidden;
      margin-right: 4px;
      margin-bottom: 4px;
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 4px);
      border-radius: 3px;

      a {
        display: block;
        padding: 16px;
        height: 96px;
        border: 1px solid transparent;
        border-radius: inherit;
        color: $grayDarkest;
        transition: border-color 0.15s ease-in-out;

        &.__disabled {
          pointer-events: none;

          .arrow {
            display: none;
          }

          &:hover {
            border-color: none !important;
          }
        }

        @media screen and (min-width: $lg) {
          height: 132px;
        }
      }

      @media screen and (min-width: $lg) {
        margin-bottom: 4px;
        width: calc(1 / 4 * 100% - (1 - 1 / 4) * 4px);

        &:nth-child(4n + 0) {
          margin-right: 0;
        }
      }

      @media screen and (max-width: calc(#{$lg} - 1px)) {
        &:nth-child(2n + 0) {
          margin-right: 0;
        }
      }
    }

    .arrow {
      position: absolute;
      top: 12px;
      right: 16px;
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: white;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }

    .title {
      display: block;
      margin-bottom: 16px;
      padding-bottom: 8px;
      font-size: 12px;
      line-height: 16px;

      @media screen and (min-width: $lg) {
        margin-bottom: 24px;
        padding-bottom: 8px;
        font-size: 16px;
      }
    }

    .number {
      color: $grayDark;
      /* identical to box height, or 100% */
      letter-spacing: -1px;
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;

      @media screen and (min-width: $lg) {
        font-size: 30px;
      }

      @media screen and (max-width: calc(#{$md} - 1px)) {
        font-size: 18px;
      }
    }

    .label {
      margin-left: 8px;
      color: $grayDark;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 14px;
    }

    .__blue {
      background-color: lighten($lightBlue, 2%);

      a:hover {
        border-color: $grayBlue;
      }

      .arrow {
        background: $grayBlue;
      }

      .title {
        border-bottom: 1px solid $grayBlue;
      }
    }

    .__notice {
      background-color: lighten($yellow, 48%);

      a {
        border: 1px solid lighten($yellow, 25%);

        &:hover {
          border-color: $yellow;
        }
      }

      .arrow {
        background: lighten($yellow, 25%);
      }

      .title {
        border-bottom: 1px solid lighten($yellow, 25%);
      }
    }
  }

  .left-box,
  .right-box {
    display: flex;
    flex-direction: row;
    padding-right: 25px;
  }

  .right-box {
    border-left: 1px solid $grayLighter;

    @media screen and (max-width: $md) {
      border-left: none;
    }
  }

  .broker-img {
    position: relative;
    overflow: hidden;
    align-self: flex-start;
    margin: 0 20px 0 0;
    min-width: 100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .read-more {
    margin-top: 34px;
    padding: 24px;
    background-color: $grayLightest;
    color: $grayDarker;
  }

  @media screen and (min-width: calc(#{$md} - 1px)) {
    .grid {
      display: flex;

      &.__gap {
        .column {
          padding-left: 32px;
        }
      }
    }
    .column {
      margin: 0;

      &.__50 {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: $md) {
    .grid.__gap.__xs {
      display: flex;
      flex-wrap: wrap;

      .column {
        padding-left: none !important;
      }
    }
  }

  /* Attributes */
  .__hide-mobile {
    @media screen and (max-width: calc(#{$md} - 1px)) {
      display: none;
    }
  }

  /* Tweaks – we should look at making the grid more practical for nesting in the future */
  .grid {
    .grid {
      margin-top: 0;
    }

    &.__gap .column .column {
      padding-left: 0;
    }
  }

  /* Data visualization */
  .chart--attributes {
    margin-top: 24px;
    height: auto;
  }

  .__asterisk {
    position: relative;
  }

  h5.__asterisk {
    &::after {
      position: absolute;
      content: "*";
      font-size: 11px;
    }
  }

  span.__asterisk {
    top: -10px;
    font-size: 8px;

    &::before {
      position: absolute;
      top: -2px;
      left: -5px;
      content: "*";
    }
  }

  [data-target="org-dashboard.costSpecification"] svg > g > g:last-child,
  [data-target="org-dashboard.chartExpenses"] svg > g > g:last-child,
  [data-target="org-dashboard.accountDevelopment"] svg > g > g:last-child {
    pointer-events: none;
  }
}
