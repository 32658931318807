.finance--debit {
  .tabulator-footer-contents {
    padding: 0 !important;
    display: block !important;
  }

  .footer {
    clear: both;
    position: relative;
    top: -12px;
    right: 0;
    box-sizing: border-box;
    width: 872px;
    height: 124px;
    border-right: 1px solid $grayLighter;
    border-bottom: 1px solid $grayLighter;
    border-left: 1px solid $grayLighter;

    .bill {
      font-weight: normal;
      font-style: normal;
      font-size: 13px;
      line-height: 24px;
    }

    .result {
      padding: 15px;
      height: 41px;
      text-align: left;
      font-weight: bold;
      font-style: normal;
      font-size: 14px;
      line-height: 17px;

      &:before {
        position: absolute;
        bottom: 75px;
        left: 0;
        margin: auto 13px;
        width: 97%;
        height: 1px;
        border: 1px solid $grayLighter;
        content: '';
      }
    }

    .right-bill-block {
      float: right;
      margin-top: 10px;
      padding: 10px 14px;
      width: 50%;
      text-align: left;
    }

    .left-bill-block {
      float: left;
      margin-top: 10px;
      padding: 10px 14px;
      text-align: left;
    }
  }

  .tabulator-tableholder {
    overflow-y: hidden !important;
    border-bottom: none !important;
  }

  .tabulator-paginator {
    float: right;
  }

  .fixate-right {
    display: flex;

    .export__tabs {
      margin-top: 0;
    }
  }

  .date_picker {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 15px;
    width: 340px;
    height: 48px;
    border: 1px solid $grayBlue;
    border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    background-color: $white;
    vertical-align: middle;
    text-align: center;
    line-height: 48px;

    div:first-of-type {
      width: 80px;
      height: 48px;
      border-radius: 3px 0 0 3px;
      background-color: $lightBlue;
      color: $grayDarkest;
      font-weight: 700;
      font-weight: bold;
      font-style: normal;
      font-size: 14px;
    }

    select {
      box-sizing: border-box;
      padding: 0 17px;
      width: 130px;
      border: 1px solid $grayLighter;
      border-radius: 3px;
      background: $white url($icon_select_down_arrow) no-repeat;
      background-position: right 15px top 50%;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      font-weight: normal;
      font-style: normal;
      font-size: 14px;
      line-height: 17px;

      appearance: none;
    }
  }

  .invoice {
    display: block;
    margin: 10;
    width: 15px;
    height: 39px;
    background: url('~icons/invoice.svg') center no-repeat;
    background-size: contain;
  }

  span {
    &.unpaid,
    &.paid,
    &.overdue {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
    }

    &.unpaid {
      background: $yellow;
    }

    &.overdue {
      background: $red;
    }

    &.paid {
      background: $green;
    }

    &.unpaid ~ span,
    &.paid ~ span,
    &.overdue ~ span {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  .totals {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    box-sizing: border-box;
    padding-left: 3px;
    width: 184px;
    height: 40px;
    border: 1px solid rgba(255, 214, 0, 0.5);
    border-radius: 3px;
    background: rgba(245, 209, 77, 0.1);
    text-align: center;
    font-size: 16px;
    line-height: 32px;

    .total-count {
      margin: auto 0;
      margin-right: 24px;
      text-align: right;
      font-weight: normal;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
    }

    .sign {
      position: relative;
      margin: auto 10px;
      width: 16px;
      height: 16px;
      border: 1px solid rgba(255, 214, 0, 0.5);
      border-radius: 50%;
      color: $yellow;
      vertical-align: middle;

      &:before {
        position: relative;
        bottom: 9.5px;
        left: 0.4px;
        content: '!';
      }
    }
  }
}
