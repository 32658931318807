.allabrf-welcome {
  overflow-x: hidden;

  .navigation__items {
    right: 25px;
  }

  .hero {
    height: 60vh;
    background-image: url($landing_photo);
    @media (max-width: $pad-width) {
      background-attachment: initial;
      background-position: 63%;
    }
  }

  .overlay__container {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    .rating {
      position: absolute;
      top: 0;
      visibility: hidden;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 75px;
        transform: translate(-50%, -50%);

        &::after {
          position: relative;
          top: 5px;
          vertical-align: super;
          font-size: 30px;
        }
      }

      &__aaa {
        top: -180px;
        left: 0;
        z-index: 5;
        visibility: visible;

        span::after {
          content: '++';
        }
      }

      &__aa {
        top: -110px;
        left: -14px;
        z-index: 4;
        visibility: visible;
        transform: scale(0.9167);

        span::after {
          content: '+';
        }
      }

      &__a {
        top: -50px;
        left: -45px;
        z-index: 3;
        visibility: visible;
        transform: scale(0.83331);
      }

      &__b {
        left: -80px;
        z-index: 2;
        visibility: visible;
        transform: scale(0.75);
      }

      &__c {
        top: 40px;
        left: -120px;
        z-index: 1;
        visibility: visible;
        transform: scale(0.6667);
      }
    }

    .quote-slider {
      position: relative;
      display: block;
      padding: 40px 20px;
      max-width: 426px;
      width: 100%;
      text-align: left;
      @media(max-width: $mobile-width) {
        padding: 0;
        max-width: initial;
        span {
          display: block;
          text-align: right;
        }
      }

      p {
        color: $gray;
        letter-spacing: 1px;
        font-weight: 300;
        font-style: italic;
        font-size: 1em;

        &:first-letter {
          margin-left: 40px;
        }
      }

      .quote-symbol {
        position: absolute;
        top: 8px;
        left: -18px;
        width: 25px;
        height: 110px;

        @media(max-width: $mobile-width) {
          top: -30px;
          left: -40px;
        }

        svg {
          width: 110px;

          g {
            opacity: 1;

            fill: $gray;
          }
        }
      }
    }
  }

  .tabs-carousel {
    .slick-list {
      overflow: unset;
    }

    &__slide {
      position: relative;
      margin-top: 0;

      &.speculants {
        .allabrf-img {
          background-image: url($landing_tab1);
        }

        .overlay__container {
          top: 60%;
          right: 120px;
          transform: translateY(-40%);
        }
      }

      &.residents {
        .img-container > .allabrf-img {
          background-image: url($landing_tab2);
        }

        .overlay__container {
          top: 55%;
          right: -35px;
          max-width: 190px;
          width: 100%;
          height: 100%;
          transform: translate(0, -43%);

          .allabrf-img {
            padding-bottom: 0;
            background-image: url($landing_tab2_mobile);
          }

          @media (max-width: $mobile-width) {
            top: 55%;
            left: 30px;
            transform: translate(0, -50%);
          }
        }
      }

      &.board {
        .img-container > .allabrf-img {
          margin-left: -220px;
          background-image: url($landing_tab3);

          @media(max-width: $mobile-width) {
            margin: 0;
          }
        }

        .overlay__container {
          position: absolute;
          left: 300px;
          width: 426px;
          transition: all 0.3s ease-in-out; //to make it smoother when being moved on resized window

          @media (max-width: $mobile-width) {
            position: relative;
            left: 0;
            margin: 1% 0 0 0;
            transform: translate(0, 0);
          }

          .slick-list {
            overflow: unset;
          }

          .slider__content {
            max-width: initial;
            width: 100%;
          }

          @media(max-width: $mobile-width) {
            width: 100%;
            .slider__content {
              margin: 0;
              min-height: 225px;
            }
          }
        }
      }
    }
  }

  .icons-row {
    margin-top: 60px;
    transition: all 0.3s ease-in-out; //to make it smoother when being moved on resized window

    .icon-item {
      @media(max-width: $mobile-width) {
        margin: 20px 0;
      }
    }
  }

  .landing-block {
    @media(max-width: $mobile-width) {
      .advantages {
        margin-left: 30px;
      }
    }
  }

  .happy-customers {
    width: 124px;
    height: 124px;

    span {
      width: 80%;
    }

    @media(max-width: $mobile-width) {
      top: -80px;
      right: -10px;
      width: 100px;
      height: 100px;
      span {
        font-size: 0.813em;
      }
    }
  }

  .carousel-slider__container {
    .inner-container {
      width: 100% !important;
    }
  }

  .geolocation-warning {
    margin-top: 20px;
  }

  .section {
    &.padding-h-20 {
      @media(max-width: $mobile-width) {
        padding-right: 10px !important;
        padding-left: 10px !important;
      }
    }
  }
}
